import React from "react";
import HeadingSection from "./components/HeadingSection/HeadingSection";
import {
  tradingConditions,
  tradeCurrencies,
} from "../../api/mockData/mainHeadingSectionMockData";
import PlatformSection from "./components/PlatformSection/PlatformSection";
import { platformCards } from "../../api/mockData/platformSectionMockData";
import PaymentSection from "./components/PaymentSection/PaymentSection";
import { cryptoCurrencies } from "../../api/mockData/fundingSectionMockData";
import ContactUs from "./components/ContactUs/ContactUs";

const Main = () => {
  return (
    <main>
      <HeadingSection currentConditions={tradingConditions} />
      <PlatformSection
        platformCard={platformCards[0]}
        currencies={tradeCurrencies}
      />
      <PaymentSection currencies={cryptoCurrencies} />
      <ContactUs />
    </main>
  );
};

export default Main;
