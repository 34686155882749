import AccountComparisonsHome from "./components/Home/AccountComparisonsHome";
import AccountComparisonsTable from "./components/Table/AccountComparisonsTable";
import AccountComparisonsCrypto from "./components/Crypto/AccountComparisonsCrypto";

const AccountComparisons = () => {
  return (
    <main>
      <AccountComparisonsHome />
      <AccountComparisonsTable />
      <AccountComparisonsCrypto />
    </main>
  );
};

export default AccountComparisons;
