import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/shared/Button/Button";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import scss from "./styles.module.scss";

type Props = {
  title: string;
  subtitle: string;
  description: string;
  links: Array<{ title: string; link: string }>;
};
const MetaTrader = (props: Props) => {
  return (
    <LayoutSection sectionClassName={scss.section}>
      <div className={scss.root}>
        <Typography level="h2" className={scss.title}>
          {props.title}
        </Typography>
        <div className={scss.rootInner}>
          <div className={scss.rootLeftBlock}>
            <Typography level="h4" className={scss.subtitle}>
              {props.subtitle}
            </Typography>
          </div>
          <div className={scss.rootRightBlock}>
            <Typography level="h5" className={scss.description}>
              {props.description}
            </Typography>
            <div className={scss.buttons}>
              {props.links.map((item, i) => (
                <Button
                  key={i}
                  className={scss.button}
                  buttonType="link"
                  type={i === 0 ? "default" : "outline"}
                  size="xl"
                  href={item.link}
                >
                  {item.title}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </LayoutSection>
  );
};

export default MetaTrader;
