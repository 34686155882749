import { memo, SVGProps } from "react";
const AppleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_229_5121)">
        <path
          d="M19.0042 23.0394C17.6524 24.3018 16.161 24.105 14.7394 23.5098C13.228 22.9026 11.8463 22.8642 10.2501 23.5098C8.2623 24.3354 7.20731 24.0954 6.01016 23.0394C-0.748746 16.3434 0.248878 6.14343 7.93059 5.75943C9.79365 5.85543 11.098 6.75063 12.1954 6.82503C13.8265 6.50583 15.3878 5.59143 17.1337 5.71143C19.2312 5.87463 20.7999 6.67143 21.8475 8.10423C17.5327 10.6002 18.5553 16.0722 22.5184 17.6082C21.7252 19.6122 20.7077 21.5922 19.0017 23.0562L19.0042 23.0394ZM12.0458 5.68743C11.8438 2.71144 14.3503 0.263437 17.2334 0.0234375C17.63 3.45543 13.9912 6.02343 12.0458 5.68743Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_229_5121">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(AppleIcon);
