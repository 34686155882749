import React from "react";
import { TradingConditionDto } from "../../../../../../api/contracts";
import scss from "./styles.module.scss";

interface IProps {
  item: TradingConditionDto;
}

const TradingCondition = ({ item }: IProps) => {
  return (
    <div className={scss.conditionItemWrapper}>
      <p className={scss.conditionItemTitle}>{item.title}</p>
      <p className={scss.conditionItemValue}>
        {item.type === "relatively"
          ? `${item.value.numerator} : ${item.value.denominator}`
          : item.value}
      </p>
    </div>
  );
};

export default TradingCondition;
