import React from "react";
import { CryptoCurrencyDto } from "../../../../../../api/contracts";
import scss from "./styles.module.scss";

interface IProps {
  currency: CryptoCurrencyDto;
}

const CryptoCurrencyItem = ({ currency }: IProps) => {
  return (
    <div className={scss.currencyItem}>
      {currency.icon}
      <p className={scss.currencyTitle}>{currency.title}</p>
      <p className={scss.currencyCaption}>{currency.caption}</p>
    </div>
  );
};

export default CryptoCurrencyItem;
