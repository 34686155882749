import { memo, SVGProps } from "react";

const WindowsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_229_8273)">
        <path
          d="M0 3.38236L9.73282 2.05685L9.73707 11.4449L0.00889091 11.5003L0 3.38236ZM9.72818 12.5266L9.73574 21.9229L0.00755455 20.5854L0.00700909 12.4636L9.72818 12.5266ZM10.908 1.88345L23.8129 0V11.3255L10.908 11.428V1.88345ZM23.8159 12.615L23.8129 23.8895L10.908 22.0682L10.8899 12.5939L23.8159 12.615Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_229_8273">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(WindowsIcon);
