import { memo, SVGProps } from "react";

const TeetherLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="340"
      height="340"
      viewBox="0 0 340 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="340" height="340" rx="170" fill="#50AF95" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.295 176.759C186.329 176.843 181.338 177.185 170.205 177.185C161.351 177.185 155.064 176.88 152.858 176.759C118.639 175.029 93.0979 168.182 93.0979 159.984C93.0979 151.785 118.639 144.947 152.858 143.189V169.94C155.096 170.125 161.504 170.56 170.358 170.56C180.984 170.56 186.305 170.051 187.262 169.949V143.208C221.409 144.957 246.894 151.804 246.894 159.984C246.894 168.163 221.417 175.011 187.262 176.75L187.295 176.759ZM187.295 140.441V116.503H234.948V80H105.205V116.503H152.85V140.432C114.124 142.477 85 151.295 85 161.862C85 172.429 114.124 181.238 152.85 183.292V260H187.287V183.264C225.925 181.219 255 172.41 255 161.853C255 151.295 225.949 142.486 187.287 140.432L187.295 140.441Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(TeetherLogo);
