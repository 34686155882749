import React from "react";
import SectionCaption from "../../../../components/shared/SectionCaption/SectionCaption";
import SectionTitle from "../../../../components/shared/SectionTitle/SectionTitle";
import SectionDescription from "../../../../components/shared/SectionDescription/SectionDescription";
import { CryptoCurrencyDto } from "../../../../api/contracts";
import CryptoCurrencyItem from "./components/CryptoCurrencyItem/CryptoCurrencyItem";
import scss from "./styles.module.scss";

interface IProps {
  currencies: CryptoCurrencyDto[];
}

const PaymentSection = ({ currencies }: IProps) => {
  return (
    <div className={scss.fundingOptionsSectionWrapper}>
      <div className={scss.sectionLayout}>
        <div className={scss.textWrapper}>
          <SectionCaption title="CONVENIENT FUNDING OPTIONS" />
          <div className={scss.titleAndDescriptionWrapper}>
            <SectionTitle
              className={scss.sectionTitle}
              title="Easy Deposit & Withdrawal with Act4 FX"
              size="medium"
            />
            <SectionDescription
              className={scss.sectionDescription}
              title="At Act4 FX, we are committed to providing user-friendly funding options, ensuring a seamless experience for our traders when depositing and withdrawing their profits."
            />
          </div>
        </div>
        <div className={scss.currencyGridWrapper}>
          {currencies.map((item, i) => (
            <CryptoCurrencyItem currency={item} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentSection;
