import React from "react";
import scss from "./styles.module.scss";
import HeaderNavBar from "../Header/components/HeaderNavBar/HeaderNavBar";
import Button from "../shared/Button/Button";
import resolutionStore from "../../store/ResolutionStore";
import { observer } from "mobx-react-lite";

const BurgerMenu = () => {
  const isMobile = resolutionStore.isMobile;
  const { tablet } = resolutionStore.resolution;

  const showActions = isMobile || tablet;
  return (
    <div className={scss.container}>
      <HeaderNavBar />
      {showActions && (
        <div className={scss.actionsWrapper}>
          <Button
            className={scss.burgerActionButton}
            type="default"
            buttonType="link"
            href="https://my.act4fx.net/en/login"
          >
            Get started
          </Button>
          <Button
            className={scss.burgerActionButton}
            type="outlineAccent"
            buttonType="link"
            href="https://my.act4fx.net/en/register"
          >
            Demo version
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(BurgerMenu);
