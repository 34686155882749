import { PlatformCardDto } from "../contracts";
import Metatrader5Icon from "../../icons/Metatrader5Icon";

export const platformCards: PlatformCardDto[] = [
  // {
  //   title: "Metatrader 4",
  //   description:
  //     "Most popular trading platform used by Forex traders. It was released in 2005, giving millions of users easy access to the financial markets.",
  //   buttonTitle: "Download mt4",
  //   downloadSrc:
  //     "https://download.mql5.com/cdn/web/18469/mt4/tradersdomainfx4setup.exe",
  //   icon: <Metatrader4Icon />,
  // },
  {
    title: "Metatrader 5",
    description:
      "MT5 is faster and more efficient than MT4, but it doesn’t replace the previous platform as it is different and simply offers more than MT4 does.",
    buttonTitle: "Download mt5",
    downloadSrc:
      "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe?utm_source=www.metatrader5.com&utm_campaign=download",
    icon: <Metatrader5Icon width={28} height={28} />,
  },
];
