import MT5Home from "../components/Home/MT5Home";
import Offers from "../components/Offers/Offers";
import AdvantagesBenefits from "../components/AdvantagesBenefits/AdvantagesBenefits";

const MT5Ipad = () => {
  return (
    <main>
      <MT5Home
        breadCrumbs="MT5 iPad"
        title="MT5 iPad"
        subtitle="Experience a new multi-panel user interface with dockable panel"
        description="Download and enjoy our Act4 FX iPad Trader, a state-of-the-art and user-friendly platform. With the MT5 software optimized for iPad, you'll have full access to your account and a seamless trading environment no matter where you are."
        links={homeLinks}
        image="/assets/mt5-ipad.webp"
      />
      <Offers
        title="Discover the following features with our iPad trader"
        items={offersItems}
        columnsCount={3}
        links={offers}
      />
      <AdvantagesBenefits
        title="ADVANTAGES & BENEFITS"
        benefits={benefitsItems}
        instructions={instructionsItems}
      />
    </main>
  );
};

export default MT5Ipad;

const homeLinks = [
  {
    title: "DOWNLOAD",
    link: "https://download.mql5.com/cdn/mobile/mt5/ios?utm_source=www.metaquotes.net&utm_campaign=install.metaquotes",
  },
];

const offers = [
  {
    content: "OPEN AN ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
  {
    content: "DEMO ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
];

const offersItems = [
  {
    title: "Real-time price monitoring for all instruments",
  },
  {
    title: "Convenient online account management",
  },
  {
    title: "Advanced charts with built-in technical analysis tools",
  },
  {
    title: "Efficient one-click trading",
  },
  {
    title: "Multiple types of Pending Orders available",
  },
  {
    title: "Access to activity and historical reports",
  },
];

const benefitsItems = [
  {
    content:
      "Stay updated with online live news from the renowned news service, FX Street",
  },
  {
    content: "Utilize the MT5 internal mailing system for easy communication",
  },
  {
    content: "Receive multilingual support for your convenience",
  },
  {
    content:
      "Experience the new multi-panel user interface with dockable panels",
  },
];

const instructionsItems = [
  [
    {
      content: "Steps to uninstall an iPad MT5 App",
      header: true,
    },
    {
      content:
        "Press and hold your finger on the icon of the application you want to uninstall or any other icon until the icons on your home screen start shaking or wiggling",
      showNumber: true,
    },
    {
      content:
        "Tap the 'x' in the upper left-hand corner of the icon for the application you want to remove",
      showNumber: true,
    },
    {
      content: "Delete the application from the iTunes applications tab",
      showNumber: true,
    },
  ],
];
