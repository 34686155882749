import { ReactNode } from "react";
import { action, computed, makeObservable, observable } from "mobx";

class SidebarStore {
  _opened: boolean = false;
  _content: ReactNode = null;

  constructor() {
    makeObservable(this, {
      _opened: observable,
      openMenu: action,
      closeMenu: action,
      state: computed,
    });
  }

  openMenu = (content: ReactNode) => {
    this._opened = true;
    this._content = content;
  };

  closeMenu = () => {
    this._opened = false;
    this._content = null;
  };

  get state() {
    return {
      opened: this._opened,
      content: this._content,
    };
  }
}

const sidebarStore = new SidebarStore()
export default sidebarStore;
