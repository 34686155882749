import Typography from "../../../../components/Typography/Typography";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import scss from "./styles.module.scss";

const AccountComparisonsCrypto = () => {
  return (
    <LayoutSection
      contentClassName={scss.sectionContent}
      sectionClassName={scss.sectionLayout}
    >
      <div className={scss.box}>
        <Typography level="p" className={scss.text}>
          *Clients can select PAMM accounts as ECN, Standard, or Islamic
          accounts based on their preferred trading style.
        </Typography>
        <Typography level="p" className={scss.text}>
          *For accounts below 100,000 USD, leverage of up to 1:500 is available.
          This leverage may be adjusted once the account equity reaches this
          level.
        </Typography>
        <Typography level="p" className={scss.text}>
          If you have any inquiries about our trading accounts or would like to
          discuss opening an account, please feel free to contact our customer
          support team via email or live chat.
        </Typography>
        <Typography level="p" className={scss.text}>
          NOTE: In certain cases, Custom Accounts can be provided. Please reach
          out to your Account Manager for more information.
        </Typography>
        <Typography level="p" className={scss.text}>
          Kindly note that there is a limit on the number of trading accounts
          that clients can open on Act4 FX MT5 trading platforms.
        </Typography>
      </div>
    </LayoutSection>
  );
};

export default AccountComparisonsCrypto;
