import MT5Home from "../components/Home/MT5Home";
import WindowsIcon from "../../../icons/WindowsIcon";
import AppleIcon from "../../../icons/AppleIcon";
import MetaTrader from "../components/MetaTrader/MetaTrader";
import Offers from "../components/Offers/Offers";
import LayoutSection from "../../../components/Layout/LayoutSection/LayoutSection";
import AdvantagesBenefits from "../components/AdvantagesBenefits/AdvantagesBenefits";
import Button from "../../../components/shared/Button/Button";
import Separator from "../../../components/shared/Separator/Separator";
import LinkIcon from "../../../icons/LinkIcon";

const MT5Terminal = () => {
  return (
    <main>
      <MT5Home
        breadCrumbs="MT5 Terminal"
        title="MT5 Terminal"
        subtitle="Experience enhanced features with the MT5 Platform"
        description="The MT5 platform combines the best features of MetaTrader 5 and customizes them to meet the needs of traders who aim to excel in the markets, regardless of their trading style or preferred assets."
        links={homeLinks}
        image="/assets/mt5-terminal.webp"
      />
      <MetaTrader
        title="MetaTrader 5 Terminal"
        subtitle="Harness the power of MetaTrader 5 with its tailored features for traders looking to master the markets, regardless of their preferred trading style or assets."
        description="This robust yet user-friendly platform offers an array of advanced trading tools, extensive support, and complete control over your trades, providing an enhanced trading experience. Start your efficient trading journey with one of the world's most innovative and popular trading platforms."
        links={metaTraderItems}
      />
      <LayoutSection style={{ backgroundColor: "var(--bg)" }}>
        <Separator type="horizontal" color="var(--grey-1)" />
      </LayoutSection>
      <Offers
        title="EXPLORE A WIDE RANGE OF TOOLS WITH THE MT5 TERMINAL"
        items={offersItems}
        columnsCount={4}
      />
      <AdvantagesBenefits
        title="ADVANTAGES & BENEFITS"
        benefits={benefitsItems}
        instructions={instructionsItems}
      />
    </main>
  );
};

export default MT5Terminal;

const instructionsItems = [
  [
    {
      content: "How to Install",
      header: true,
    },
    {
      content: <span>Download the platform</span>,
      showNumber: true,
    },
    {
      content: "Run the .exe file",
      showNumber: true,
    },
    {
      content: "Log in to your MT5 account",
      showNumber: true,
    },
  ],
  [
    {
      content: "How to Uninstall",
      header: true,
    },
    {
      content: "Go to Start menu",
      showNumber: true,
    },
    {
      content: "Find your MT5 application in the list",
      showNumber: true,
    },
    {
      content: "Right click on it and select Uninstall",
      showNumber: true,
    },
  ],
  [
    {
      content: "User guide how to",
      header: true,
    },
    {
      content: (
        <>
          <div>
            <Button
              buttonType="link"
              type="outFilled"
              size="s"
              href="/"
              upperCase={false}
            >
              Connect to the MT5 Platform Servers
            </Button>
          </div>
          <LinkIcon color="var(--font)" />
        </>
      ),
    },
    {
      content: (
        <>
          <div>
            <Button
              buttonType="link"
              type="outFilled"
              size="s"
              href="/"
              upperCase={false}
            >
              Download the MT5 Platform on a Mac
            </Button>
          </div>
          <LinkIcon color="var(--font)" />
        </>
      ),
    },
    {
      content: "",
    },
  ],
];

const benefitsItems = [
  {
    content: "Enjoy complete access to your account and order history",
  },
  {
    content: "Maintain absolute control over your trades at all times",
  },
  {
    content: "Experience improved order management capabilities",
  },
  {
    content: "Effortlessly set Stop Loss and Take Profit levels",
  },
];

const offersItems = [
  {
    title: (
      <span>
        Explore 21 different <br /> timeframes
      </span>
    ),
  },
  {
    title: (
      <span>
        Utilize advanced <br /> analysis tools
      </span>
    ),
  },
  {
    title: "Access and Trade a wide range of FX Asset classes",
  },
  {
    title: "Effortlessly view up to 100 charts simultaneously",
  },
  {
    title: "Benefit from hedging capabilities",
  },
  {
    title: "Execute trades with a single click",
  },
  {
    title: (
      <span>
        Stay informed with the built-in <br />
        economic calendar
      </span>
    ),
  },
  {
    title: (
      <span>
        Utilize over 80 technical <br />
        indicators for analysis
      </span>
    ),
  },
];

const metaTraderItems = [
  {
    title: "OPEN AN ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
  {
    title: "DEMO ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
];

const homeLinks = [
  {
    title: "DOWNLOAD FOR",
    link: "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe?utm_source=www.metaquotes.net&utm_campaign=download",
    icon: <WindowsIcon />,
  },
  {
    title: "DOWNLOAD FOR",
    link: "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg?utm_source=www.metaquotes.net&utm_campaign=download.mt5.macos",
    icon: <AppleIcon />,
  },
];
