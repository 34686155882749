import React from "react";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/shared/Button/Button";
import scss from "./styles.module.scss";

const PaymentProcessingGuideSection = () => {
  return (
    <LayoutSection
      contentClassName={scss.layoutSectionContent}
      sectionClassName={scss.layoutSection}
    >
      <Typography level="h2">Payment Process</Typography>
      <Typography className={scss.sectionText}>
        To ensure faster processing, we recommend that all account holders
        deposit funds into their trading account from within their Secure Client
        Area. Using the Secure Client Area, you can fund your account in real
        time using Crypto.
      </Typography>
      <div className={scss.cardsContainer}>
        <div className={scss.cardWrapper}>
          <Typography className={scss.cardText}>
            If you don't have access to our Secure Client Area yet, please
            create an account here:
          </Typography>
          <Button
            className={scss.cardButton}
            buttonType="link"
            href="https://my.act4fx.net/en/register"
            type="default"
          >
            Open an Account
          </Button>
        </div>
        <div className={scss.cardWrapper}>
          <Typography className={scss.cardText}>
            All account holders are required to submit withdrawal requests from
            within their Client Area.
          </Typography>
          <Button
            className={scss.cardButton}
            buttonType="link"
            href="https://my.act4fx.net/en/login"
            type="default"
          >
            Client Area
          </Button>
        </div>
        <div className={scss.cardWrapper}>
          <Typography className={scss.cardText}>
            If you have lost access to your Secure Client Portal, click here to
            reset your password.
          </Typography>
          <Button
            className={scss.cardButton}
            buttonType="link"
            href="https://my.act4fx.net/en/restore"
            type="default"
          >
            Restore Access
          </Button>
        </div>
      </div>
      <Typography level="h2" className={scss.sectionTitle}>
        Security of Funds
      </Typography>
      <Typography className={scss.sectionText}>
        When funding your trading account, client money is held in Segregated
        Client Trust Accounts. Electronic payments are processed using SSL
        (Secure Socket Layer) technology and are encrypted to ensure security.
        All payment information is confidential and used only for the purpose of
        funding your trading account with Act4 FX.
      </Typography>
      <div className={scss.withdrawalTermsWrapper}>
        <Typography level="h3" className={scss.withdrawalConditionsTitle}>
          Withdrawal Conditions Terms:
        </Typography>
        <Typography className={scss.withdrawalConditionsText}>
          Once approved, withdrawal requests are typically processed within
          24-48 hours.
          <br />
          <br />
          Act4 FX does not charge any additional fees for deposits or
          withdrawals. However, please be aware that you may incur fees on
          payments to and from certain deposit/withdrawal methods. Act4 FX
          accepts no responsibility for any such fees.
        </Typography>
      </div>
    </LayoutSection>
  );
};

export default PaymentProcessingGuideSection;
