import { CSSProperties, ReactNode } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  children: ReactNode;
  sectionClassName?: string;
  contentClassName?: string;
  hideMobilePadding?: boolean;
  style?: CSSProperties;
  hideTabletPadding?: boolean;
};
const LayoutSection = (props: Props) => {
  return (
    <div
      className={cn(scss.section, props.sectionClassName)}
      style={props.style}
    >
      <div
        className={cn(scss.content, props.contentClassName, {
          [scss.hideMobilePadding]: props.hideMobilePadding,
          [scss.hideTabletPadding]: props.hideTabletPadding,
        })}
      >
        {props.children}
      </div>
    </div>
  );
};

export default LayoutSection;
