import TextWithShadow from "../../../../components/shared/TextWithShadow/TextWithShadow";
import Typography from "../../../../components/Typography/Typography";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Button from "../../../../components/shared/Button/Button";
import scss from "./styles.module.scss";

const ConditionsSections = () => {
  return (
    <LayoutSection
      contentClassName={scss.sectionContent}
      sectionClassName={scss.sectionLayout}
    >
      <div className={scss.container}>
        <div className={scss.grid}>
          {items.map((item, index) => (
            <div key={index} className={scss.gridItem}>
              <TextWithShadow
                title={item.title}
                className={scss.itemTitle}
                gradientText={false}
              />
              <Typography level="p" className={scss.itemCaption}>
                {item.caption}
              </Typography>
              <Typography level="p" className={scss.itemContent}>
                {item.content}
              </Typography>
            </div>
          ))}
        </div>
        <div className={scss.buttons}>
          <Button
            buttonType="link"
            href="https://my.act4-fx.com/en/register"
            type="default"
            size="xl"
            horizontalPaddings="paddingXL"
          >
            OPEN AN ACCOUNT
          </Button>
          <Button
            buttonType="link"
            href="https://my.act4-fx.com/en/register"
            type="outline"
            size="xl"
            horizontalPaddings="paddingXL"
          >
            DEMO ACCOUNT
          </Button>
        </div>
      </div>
    </LayoutSection>
  );
};

export default ConditionsSections;

const items = [
  {
    title: "SPREADS",
    caption:
      "Our spreads are highly competitive, sourced from over 25 different pricing providers to ensure you trade at the best available price.",
    content:
      "Our MetaTrader 5 and RAW platforms offer spreads as low as 0.0 pips, giving you access to tight pricing on your trades.",
  },
  {
    title: "EXECUTION",
    caption:
      "Experience fast execution with an average speed of under 40ms once your order is received by Act4.",
    content:
      "We prioritize speed and accuracy in executing your trades. With our ultra-fast servers in New York and London, you can access deep market liquidity and enjoy one of the fastest execution speeds in the industry.",
  },
  {
    title: "MARGIN & LEVERAGE",
    caption:
      "Take advantage of leverage up to 1:500, allowing retail traders to access the forex market with smaller initial capital.",
    content:
      "By offering high leverage, we enable traders to amplify their potential gains. However, it's important to note that leverage also carries the risk of magnifying losses beyond the initial deposit.",
  },
  {
    title: "CURRENCY PAIRS",
    caption:
      "Trade on 60 different currency pairs and 4 metals across all our trading platforms.",
    content:
      "Act4 offers competitive pricing on a wide range of currency pairs by aggregating quotes from top pricing sources. This, combined with our fast execution speeds, makes Act4 FX a preferred choice for day traders, scalpers, and those using Expert Advisors (EAs).",
  },
  {
    title: "SWAP RATES",
    caption:
      "Access long and short swap rates for all our currency pairs and metals.",
    content:
      "Swap rates are determined by the overnight interest rate differential between the two currencies in a pair and depend on whether the position is a buy (long) or sell (short).",
  },
  {
    title: "TRADING HOURS",
    caption: "Easily find the trading hours for our broad range of products.",
    content:
      "Stay informed about market opening and closing times for our products. You can view the trading hours within the MetaTrader trading terminals or refer to our provided schedule.",
  },
];
