import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Main from "../pages/Main/Main";
import AmlPolicy from "../pages/Policies/AmlPolicy";
import CustomerAgreement from "../pages/Policies/CustomerAgreement";
import PrivacyPolicy from "../pages/Policies/PrivacyPolicy";
import TermsAndConditions from "../pages/Policies/TermsAndConditions";
import PaymentProcessing from "../pages/PaymentProcessing/PaymentProcessing";
import PortfolioDiversification from "../pages/PortfolioDiversification/PortfolioDiversification";
import SwapRates from "../pages/SwapRates/SwapRates";
import TradingConditions from "../pages/TradingConditions/TradingConditions";
import TradingHours from "../pages/TradingHours/TradingHours";
import AccountComparisons from "../pages/AccountComparisons/AccountComparisons";
import MT5Terminal from "../pages/MT5/MT5Terminal/MT5Terminal";
import MT5Android from "../pages/MT5/MT5Android/MT5Android";
import MT5Ipad from "../pages/MT5/MT5IPad/MT5IPad";
import MT5WebTerminal from "../pages/MT5/MT5WebTerminal/MT5WebTerminal";
import MT5IPhone from "../pages/MT5/MT5IPhone/MT5IPhone";

const Router = () => {
  return (
    <Routes>
      <Route path="/aml-policy" element={<AmlPolicy />} />
      <Route path="/costumer-agreement" element={<CustomerAgreement />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/payment-processing" element={<PaymentProcessing />} />
      <Route
        path="/portfolio-diversification"
        element={<PortfolioDiversification />}
      />
      <Route path="/swap-rates" element={<SwapRates />} />
      <Route path="/trading-conditions" element={<TradingConditions />} />
      <Route path="/trading-hours" element={<TradingHours />} />
      <Route path="/account-comparisons" element={<AccountComparisons />} />
      <Route path="/mt5-terminal" element={<MT5Terminal />} />
      <Route path="/mt5-android" element={<MT5Android />} />
      <Route path="/mt5-ipad" element={<MT5Ipad />} />
      <Route path="/mt5-web-terminal" element={<MT5WebTerminal />} />
      <Route path="/mt5-iphone" element={<MT5IPhone />} />
      <Route path="/" element={<Main />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;
