import React, { MouseEventHandler, ReactNode, useMemo } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import scss from "./styles.module.scss";

type ButtonProps = {
  buttonType: "button";
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

type LinkProps = {
  buttonType: "link";
  href: string;
};

type InnerLinkProps = {
  buttonType: "innerLink";
  to: string;
};

type IProps = {
  children: ReactNode;
  size: "xs" | "s" | "sm" | "m" | "l" | "xl";
  borderRadius: "defaultBorder" | "roundBorder";
  type:
    | "gradient"
    | "outline"
    | "outlineAccent"
    | "default"
    | "outFilled"
    | "outFilledAccent";
  horizontalPaddings: "paddingS" | "paddingM" | "paddingL" | "paddingXL";
  disabled?: boolean;
  upperCase?: boolean;
  active?: boolean;
  iconAfter?: ReactNode;
  className?: string;
} & (ButtonProps | LinkProps | InnerLinkProps);

const Button = (props: IProps) => {
  const className = useMemo(
    () =>
      cn(
        scss.button,
        scss[props.size],
        scss[props.type],
        scss[props.borderRadius],
        scss[props.horizontalPaddings],
        {
          [scss.disabled]: props.disabled,
          [scss.upperCase]: props.upperCase,
          [scss.active]: props.active,
        },
        props.className
      ),
    [props]
  );

  switch (props.buttonType) {
    case "button":
      return (
        <button className={className} onClick={props.onClick}>
          {props.children}
          {props.iconAfter ? <span>{props.iconAfter}</span> : null}
        </button>
      );
    case "link":
      return (
        <a className={className} href={props.href}>
          {props.children}
          {props.iconAfter ? <span>{props.iconAfter}</span> : null}
        </a>
      );
    case "innerLink":
      return (
        <NavLink className={className} to={props.to}>
          {props.children}
          {props.iconAfter ? <span>{props.iconAfter}</span> : null}
        </NavLink>
      );
    default:
      return null;
  }
};

Button.defaultProps = {
  size: "m",
  borderRadius: "defaultBorder",
  type: "default",
  horizontalPaddings: "paddingM",
  upperCase: true,
};

export default Button;
