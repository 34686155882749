import MT5Home from "../components/Home/MT5Home";
import Offers from "../components/Offers/Offers";
import AdvantagesBenefits from "../components/AdvantagesBenefits/AdvantagesBenefits";

const MT5WebTerminal = () => {
  return (
    <main>
      <MT5Home
        breadCrumbs="MT5 WebTerminal"
        title="MT5 WebTerminal"
        subtitle="Experience the Full Terminal Features Without Download"
        description="With MT5 WebTerminal, you can trade without the need to download any additional software. Launch the WebTerminal on any computer and browser, and start trading within seconds, enjoying all the powerful functions available in the desktop platform!"
        links={homeLinks}
        image="/assets/mt5-web-terminal.webp"
      />
      <Offers
        title="Efficient and Convenient Trading on the Web"
        items={offersItems}
        columnsCount={3}
        links={offers}
      />
      <AdvantagesBenefits
        title="ADVANTAGES & BENEFITS"
        benefits={benefitsItems}
      />
    </main>
  );
};

export default MT5WebTerminal;

const homeLinks = [
  {
    title: "LAUNCH MT5",
    link: "https://www.mql5.com/en/trading",
  },
];

const offers = [
  {
    content: "OPEN AN ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
  {
    content: "DEMO ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
];

const offersItems = [
  {
    title: "Compatible with any browser and operating system",
  },
  {
    title: "Utilize advanced analytical tools for market analysis",
  },
  {
    title: "Monitor all trading activity in real-time",
  },
  {
    title: "Execute trades with just one click",
  },
  {
    title: "Experience enhanced platform speed for efficient trading",
  },
  {
    title: "Benefit from the option to hedge positions",
  },
];

const benefitsItems = [
  {
    content:
      "Stay updated with online live news from the award-winning news service, FX Street",
  },
  {
    content:
      "Utilize the MT5 internal mailing system for seamless communication",
  },
  {
    content: "Receive multilingual support for global accessibility",
  },
  {
    content:
      "Enjoy a new multi-panel user interface with customizable dockable panels",
  },
];
