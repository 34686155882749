import MT5Home from "../components/Home/MT5Home";
import Offers from "../components/Offers/Offers";
import AdvantagesBenefits from "../components/AdvantagesBenefits/AdvantagesBenefits";

const MT5Iphone = () => {
  return (
    <main>
      <MT5Home
        breadCrumbs="MT5 iPhone"
        title="MT5 iPhone"
        subtitle="The trading platform that accompanies you wherever you go"
        description="The iPhone Trader is a trading platform specifically designed for iPhone users, offering full functionality and the flexibility to trade on-the-go. As long as you have a Wi-Fi or 3G connection, you can access your account and execute trades from anywhere!"
        links={homeLinks}
        image="/assets/mt5-iphone.webp"
      />
      <Offers
        title="Experience seamless trading with the MT5 iPhone trader"
        items={offersItems}
        columnsCount={3}
        links={offers}
      />
      <AdvantagesBenefits
        title="ADVANTAGES & BENEFITS"
        benefits={benefitsItems}
        instructions={instructionsItems}
      />
    </main>
  );
};

export default MT5Iphone;

const homeLinks = [
  {
    title: "DOWNLOAD",
    link: "https://download.mql5.com/cdn/mobile/mt5/ios?utm_source=www.metaquotes.net&utm_campaign=install.metaquotes",
  },
];

const offers = [
  {
    content: "OPEN AN ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
  {
    content: "DEMO ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
];

const offersItems = [
  {
    title: "Access your trading account from anywhere",
  },
  {
    title: "Interactive charts available in nine different timeframes",
  },
  {
    title: "Utilize advanced trading functions",
  },
  {
    title: "Execute trades with just one click",
  },
  {
    title: "Benefit from 24 analytical objects",
  },
  {
    title: "Access a wide range of 30 technical indicators",
  },
];

const benefitsItems = [
  {
    content:
      "Perform trading operations, send pending orders, and set stop loss (SL) and take profit (TP) levels",
  },
  {
    content: "Access and review your trading history",
  },
  {
    content: "Take advantage of hedging capabilities",
  },
];

const instructionsItems = [
  [
    {
      content: "Steps to uninstall an iPhone MT5 App",
      header: true,
    },
    {
      content:
        "Press and hold your finger on the icon of the application you want to uninstall or any other icon until the icons on your home screen start shaking or wiggling",
      showNumber: true,
    },
    {
      content:
        "Tap the 'x' in the upper left-hand corner of the icon for the application you want to remove",
      showNumber: true,
    },
    {
      content: "Delete the application from the iTunes applications tab",
      showNumber: true,
    },
  ],
];
