import React, { useEffect, useState } from "react";
import scss from "./styles.module.scss";
import Button from "../shared/Button/Button";
import resolutionStore from "../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import BurgerButton from "../BurgerMenu/BurgerButton";
import { NavLink } from "react-router-dom";

import Logo from "../../assets/images/Logo.svg";
import MenuItem from "../shared/MenuItem/MenuItem";
import cn from "classnames";
import Separator from "../shared/Separator/Separator";

const Header = () => {
  const [filled, setFilled] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const isMobile = resolutionStore.isMobile;
  const isLaptop = resolutionStore.isLaptop;
  const { tablet } = resolutionStore.resolution;

  useEffect(() => {
    const handleScroll = () => {
      setFilled(window.scrollY > 72);
      setScrolled(window.scrollY > 72);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showActions = !(isMobile || tablet);

  return (
    <header
      className={cn(scss.headerContainer, { [scss.withBackground]: filled })}
    >
      <div className={scss.headerWrapper}>
        <NavLink className={scss.logoWrapper} to="/">
          <img src={Logo} alt="logo" />
        </NavLink>
        {isLaptop && (
          <div className={scss.headerMenu}>
            <MenuItem
              title={"Trading"}
              subMenu={
                <div className={scss.submenuWrapper} style={{ width: 607 }}>
                  <div className={scss.subMenuContent}>
                    <Button
                      size="s"
                      buttonType={"innerLink"}
                      type="outFilled"
                      to="/account-comparisons"
                      className={scss.headerLink}
                    >
                      Account Compraison
                    </Button>
                    <Button
                      size="s"
                      buttonType={"innerLink"}
                      type="outFilled"
                      to="/swap-rates"
                      className={scss.headerLink}
                    >
                      Swap Rates
                    </Button>
                  </div>
                  <div className={scss.subMenuContent}>
                    <p className={scss.headerMenuTitle}>Markets</p>
                    <Button
                      size="s"
                      buttonType={"innerLink"}
                      type="outFilled"
                      to="/portfolio-diversification"
                      className={scss.headerLink}
                    >
                      Portfolio Deversification
                    </Button>
                    <Button
                      size="s"
                      buttonType={"innerLink"}
                      type="outFilled"
                      to="/trading-hours"
                      className={scss.headerLink}
                    >
                      Trading Hours
                    </Button>
                    <Button
                      size="s"
                      buttonType={"innerLink"}
                      type="outFilled"
                      to="/trading-conditions"
                      className={scss.headerLink}
                    >
                      Trading Conditions
                    </Button>
                  </div>
                </div>
              }
            />
            <MenuItem
              title={"Our Platform"}
              subMenu={
                <div className={scss.subMenuContent} style={{ width: 337 }}>
                  <p className={scss.headerMenuTitle}>MT5 Avaible Platfroms</p>
                  <Button
                    size="s"
                    buttonType={"innerLink"}
                    type="outFilled"
                    to="/mt5-web-terminal"
                    className={scss.headerLink}
                  >
                    MT5 WebTrader
                  </Button>
                  <Button
                    size="s"
                    buttonType={"innerLink"}
                    type="outFilled"
                    to="/mt5-terminal"
                    className={scss.headerLink}
                  >
                    MT5 Terminal (Desktop)
                  </Button>
                  <Button
                    size="s"
                    buttonType={"innerLink"}
                    type="outFilled"
                    to="/mt5-android"
                    className={scss.headerLink}
                  >
                    MT5 Android
                  </Button>
                  <Button
                    size="s"
                    buttonType={"innerLink"}
                    type="outFilled"
                    to="/mt5-iphone"
                    className={scss.headerLink}
                  >
                    MT5 Iphone
                  </Button>
                  <Button
                    size="s"
                    buttonType={"innerLink"}
                    type="outFilled"
                    to="/mt5-ipad"
                    className={scss.headerLink}
                  >
                    MT5 IPad
                  </Button>
                </div>
              }
            />
            <MenuItem
              title={"Partners & More"}
              subMenu={
                <div className={scss.subMenuContent} style={{ width: 311 }}>
                  <Button
                    size="s"
                    buttonType={"link"}
                    type="outFilled"
                    href="/"
                    className={scss.headerLink}
                  >
                    Partners Program
                  </Button>
                  <p className={scss.headerMenuTitle}>Support & Resources</p>
                  <Button
                    size="s"
                    buttonType={"link"}
                    type="outFilled"
                    href="/"
                    className={scss.headerLink}
                  >
                    FAQ
                  </Button>
                  <Button
                    size="s"
                    buttonType={"link"}
                    type="outFilled"
                    href="/"
                    className={scss.headerLink}
                  >
                    Customer Portal
                  </Button>
                  <Button
                    size="s"
                    buttonType={"link"}
                    type="outFilled"
                    href="/"
                    className={scss.headerLink}
                  >
                    Contact US
                  </Button>
                </div>
              }
            />
            <MenuItem
              title={"Payments"}
              subMenu={
                <div className={scss.subMenuContent} style={{ width: 115 }}>
                  <Button
                    size="s"
                    buttonType={"link"}
                    type="outFilled"
                    href="/payment-processing"
                    className={scss.headerLink}
                  >
                    Deposit
                  </Button>
                  <Button
                    size="s"
                    buttonType={"link"}
                    type="outFilled"
                    href="/payment-processing?withdrawals"
                    className={scss.headerLink}
                  >
                    Withdrawals
                  </Button>
                </div>
              }
            />
          </div>
        )}
        {showActions && (
          <div className={scss.actionsWrapper}>
            <Separator type="vertical" />
            <Button
              type="outFilledAccent"
              buttonType="link"
              size="sm"
              horizontalPaddings="paddingL"
              href="https://my.act4fx.net/en/login"
              className={scss.loginButton}
            >
              Log In
            </Button>
            <Button
              buttonType="link"
              size="sm"
              href="https://my.act4fx.net/en/register"
              className={scss.getStartedButton}
            >
              Get started
            </Button>
          </div>
        )}
        {!isLaptop && (
          <BurgerButton openHandler={!scrolled ? setFilled : undefined} />
        )}
      </div>
    </header>
  );
};

export default observer(Header);
