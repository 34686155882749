import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Button from "../../../../components/shared/Button/Button";
import Typography from "../../../../components/Typography/Typography";
import TextWithShadow from "../../../../components/shared/TextWithShadow/TextWithShadow";
import PaymentProcessingTable, {
  CryptoConditionsTableDto,
} from "./components/PaymentProcessingTable/PaymentProcessingTable";
import { useSearchParams } from "react-router-dom";
import scss from "./styles.module.scss";

const PaymentProcessingHeaderSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeOption, setActiveOption] = useState<"deposit" | "withdrawals">(
    "deposit"
  );

  const clickHandler = (type: "deposit" | "withdrawals") => () =>
    setActiveOption(type);

  useEffect(() => {
    const query = window.location.search;

    if (query.includes("withdrawals")) {
      setActiveOption("withdrawals");
      searchParams.delete("withdrawals");
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <LayoutSection
      style={{
        backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
      }}
      contentClassName={scss.layoutSectionContent}
      sectionClassName={scss.layoutSectionWrapper}
    >
      <Breadcrumbs position="left" title="Payment Processing" />
      <Typography level="h1" className={scss.pageTitle}>
        Payment Processing
      </Typography>
      <div className={scss.actionsWrapper}>
        <Button
          onClick={clickHandler("deposit")}
          className={scss.button}
          buttonType="button"
          type={activeOption === "deposit" ? "default" : "outlineAccent"}
          borderRadius="defaultBorder"
          active={activeOption === "deposit"}
        >
          deposit
        </Button>
        <Button
          onClick={clickHandler("withdrawals")}
          className={scss.button}
          buttonType="button"
          type={activeOption === "withdrawals" ? "default" : "outlineAccent"}
          borderRadius="defaultBorder"
          active={activeOption === "withdrawals"}
        >
          WITHDRAWALS
        </Button>
      </div>
      <PaymentProcessingTable
        table={
          activeOption === "deposit"
            ? depositTable.table
            : withdrawalsTable.table
        }
      />
      <div className={scss.cardsWrapper}>
        <div className={scss.cardItem}>
          <TextWithShadow title="24 / 7" className={scss.shadowText} />
          <p className={scss.cardText}>Fast transactions</p>
        </div>
        <div className={scss.cardItem}>
          <TextWithShadow title="no fees" className={scss.shadowText} />
          <p className={scss.cardText}>We does not apply any additional fees</p>
        </div>
      </div>
    </LayoutSection>
  );
};

export default PaymentProcessingHeaderSection;

const depositTable: CryptoConditionsTableDto = {
  table: {
    rows: [
      {
        type: "heading",
        items: [
          { key: "Options", value: "Options" },
          { key: "Max. Deposit", value: "Max. Deposit" },
          { key: "Min. Deposit", value: "Min. Deposit" },
          { key: "Deposit Time", value: "Deposit Time" },
          { key: "Deposit Fees", value: "Deposit Fees" },
        ],
      },
      {
        type: "content",
        items: [
          { key: "Options", value: "Deposit using Crypto" },
          { key: "Max. Deposit", value: "No Limit" },
          { key: "Min. Deposit", value: "$100" },
          { key: "Deposit Time", value: "Instant" },
          { key: "Deposit Fees", value: "2.5%" },
        ],
      },
    ],
  },
};

const withdrawalsTable: CryptoConditionsTableDto = {
  table: {
    rows: [
      {
        type: "heading",
        items: [
          { key: "Options", value: "Options" },
          { key: "Max. Withdrawal", value: "Max. Withdrawal" },
          { key: "Min. Withdrawal", value: "Min. Withdrawal" },
          { key: "Withdrawal Time", value: "Withdrawal Time" },
          { key: "Withdrawal Fees", value: "Withdrawal Fees" },
        ],
      },
      {
        type: "content",
        items: [
          { key: "Options", value: "Withdrawal using Crypto" },
          { key: "Max. Withdrawal", value: "No Limit" },
          { key: "Min. Withdrawal", value: "$100" },
          { key: "Withdrawal Time", value: "Instant" },
          { key: "Withdrawal Fees", value: "2.5%" },
        ],
      },
    ],
  },
};
