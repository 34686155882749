import { memo, SVGProps } from "react";

const MoneroLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="340"
      height="340"
      viewBox="0 0 340 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_152_2789)">
        <path
          d="M339.996 169.983C339.996 263.859 263.892 339.966 170 339.966C76.1072 339.966 0.00366211 263.859 0.00366211 169.983C0.00366211 76.1072 76.109 0 170 0C263.89 0 339.996 76.0999 339.996 169.983Z"
          fill="white"
        />
        <path
          d="M169.999 0C76.1405 0 -0.0978613 76.2149 0.0216246 169.978C0.0451597 188.737 3.03683 206.782 8.65177 223.661H59.513V80.6671L169.999 191.141L280.48 80.6671V223.664H331.353C336.976 206.786 339.952 188.741 339.985 169.981C340.146 76.1198 263.868 0.0226275 169.999 0.0226275V0Z"
          fill="#F26822"
        />
        <path
          d="M144.591 216.538L96.3736 168.323V258.303H59.5094L24.7236 258.309C54.5616 307.257 108.486 340 170 340C231.513 340 285.44 307.25 315.284 258.302H243.615V168.323L195.395 216.538L169.994 241.936L144.592 216.538H144.591Z"
          fill="#4D4D4D"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_2789">
          <rect width="340" height="340" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(MoneroLogo);
