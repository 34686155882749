import cn from "classnames";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Button from "../../../../components/shared/Button/Button";
import { ReactNode } from "react";
import FlagIcon from "../../../../icons/FlagIcon";
import PlusIcon from "../../../../icons/PlusIcon";
import scss from "./styles.module.scss";

const AccountComparisonsTable = () => {
  const table = data.map((rows, columnIndex) => {
    return (
      <tr className={scss.row} key={columnIndex}>
        {rows.map((cell, index) =>
          !columnIndex ? (
            <th
              key={index}
              className={cn(scss.cell, scss.cellHeader, {
                [scss.active]: cell.active,
              })}
            >
              <div className={scss.cellInner}>
                {cell.icon}
                {cell.content}
              </div>
            </th>
          ) : (
            <td className={scss.cell} key={index}>
              {cell.caption ? (
                <div className={scss.cellCaption}>{cell.caption}</div>
              ) : null}
              {cell.content}
              {cell.description ? (
                <div className={scss.cellDescription}>{cell.description}</div>
              ) : null}
            </td>
          )
        )}
      </tr>
    );
  });
  return (
    <LayoutSection hideTabletPadding sectionClassName={scss.section}>
      <table className={scss.table}>
        <tbody>{table}</tbody>
      </table>
    </LayoutSection>
  );
};

export default AccountComparisonsTable;

const data: Array<
  Array<{
    icon?: ReactNode;
    content?: ReactNode;
    description?: ReactNode;
    active?: boolean;
    caption?: ReactNode;
  }>
> = [
  [
    {
      icon: (
        <span className={scss.icon}>
          <FlagIcon />
        </span>
      ),
      content: (
        <span>
          Choose your <br />
          account type
        </span>
      ),
    },
    {
      content: "ECN",
    },
    {
      content: "Standard",
      active: true,
    },
    {
      content: "Islamic",
    },
    {
      content: "PAMM*",
    },
  ],
  [
    {
      content: "Trading Platforms",
    },
    {
      caption: "Trading Platforms",
      content: (
        <span>
          MetaTrader 5,
          <br />
          Webtrader & Mobile Trading
        </span>
      ),
    },
    {
      caption: "Trading Platforms",
      content: (
        <span>
          MetaTrader 5,
          <br />
          Webtrader & Mobile Trading
        </span>
      ),
    },
    {
      caption: "Trading Platforms",
      content: (
        <span>
          MetaTrader 5,
          <br />
          Webtrader & Mobile Trading
        </span>
      ),
    },
    {
      caption: "Trading Platforms",
      content: (
        <span>
          MetaTrader 5,
          <br />
          Webtrader & Mobile Trading
        </span>
      ),
    },
  ],
  [
    {
      content: "Spreads Types",
    },
    {
      caption: "Spreads Types",
      content: "Variable",
    },
    {
      caption: "Spreads Types",
      content: "Variable",
    },
    {
      caption: "Spreads Types",
      content: "Variable",
    },
    {
      caption: "Spreads Types",
      content: "Variable",
    },
  ],
  [
    {
      content: "Spreads Pips",
    },
    {
      caption: "Spreads Pips",
      content: "From 0 pips",
    },
    {
      caption: "Spreads Pips",
      content: "From 1.0 pips",
    },
    {
      caption: "Spreads Pips",
      content: "From 0.6 pips",
    },
    {
      caption: "Spreads Pips",
      content: "From 0 pips",
    },
  ],
  [
    {
      content: "Trading Instruments",
    },
    {
      caption: "Trading Instruments",
      content: (
        <Button
          buttonType="innerLink"
          type="outFilled"
          to="/portfolio-diversification"
          size="xs"
          upperCase={false}
          className={scss.link}
        >
          All Available
        </Button>
      ),
      description: "Click to view full list",
    },
    {
      caption: "Trading Instruments",
      content: (
        <Button
          buttonType="innerLink"
          type="outFilled"
          to="/portfolio-diversification"
          size="xs"
          upperCase={false}
          className={scss.link}
        >
          All Available
        </Button>
      ),
      description: "Click to view full list",
    },
    {
      caption: "Trading Instruments",
      content: (
        <Button
          buttonType="innerLink"
          type="outFilled"
          to="/portfolio-diversification"
          size="xs"
          upperCase={false}
          className={scss.link}
        >
          All Available
        </Button>
      ),
      description: "Click to view full list",
    },
    {
      caption: "Trading Instruments",
      content: (
        <Button
          buttonType="innerLink"
          type="outFilled"
          to="/portfolio-diversification"
          size="xs"
          upperCase={false}
          className={scss.link}
        >
          All Available
        </Button>
      ),
      description: "Click to view full list",
    },
  ],
  [
    {
      content: "Min. Deposit",
    },
    {
      caption: "Min. Deposit",
      content: "$100",
    },
    {
      caption: "Min. Deposit",
      content: "$100",
    },
    {
      caption: "Min. Deposit",
      content: "$100",
    },
    {
      caption: "Min. Deposit",
      content: "$100",
    },
  ],
  [
    {
      content: "Min. trade size (Lots)",
    },
    {
      caption: "Min. trade size (Lots)",
      content: <b>0.01 LOTS</b>,
      description: "1,000 units of base currency",
    },
    {
      caption: "Min. trade size (Lots)",
      content: <b>0.01 LOTS</b>,
      description: "1,000 units of base currency",
    },
    {
      caption: "Min. trade size (Lots)",
      content: <b>0.01 LOTS</b>,
      description: "1,000 units of base currency",
    },
    {
      caption: "Min. trade size (Lots)",
      content: <b>0.01 LOTS</b>,
      description: "1,000 units of base currency",
    },
  ],
  [
    {
      content: "Fifth Decimal",
    },
    {
      caption: "Fifth Decimal",
      content: <PlusIcon />,
    },
    {
      caption: "Fifth Decimal",
      content: <PlusIcon />,
    },
    {
      caption: "Fifth Decimal",
      content: <PlusIcon />,
    },
    {
      caption: "Fifth Decimal",
      content: <PlusIcon />,
    },
  ],
  [
    {
      content: "Max. Leverage",
    },
    {
      caption: "Max. Leverage",
      content: "1:500*",
    },
    {
      caption: "Max. Leverage",
      content: "1:500*",
    },
    {
      caption: "Max. Leverage",
      content: "1:500*",
    },
    {
      caption: "Max. Leverage",
      content: "1:500*",
    },
  ],
  [
    {
      content: "Market Execution",
    },
    {
      caption: "Market Execution",
      content: <PlusIcon />,
    },
    {
      caption: "Market Execution",
      content: <PlusIcon />,
    },
    {
      caption: "Market Execution",
      content: <PlusIcon />,
    },
    {
      caption: "Market Execution",
      content: <PlusIcon />,
    },
  ],
  [
    {
      content: "Trade size increment",
    },
    {
      caption: "Trade size increment",
      content: "0.01",
    },
    {
      caption: "Trade size increment",
      content: "0.01",
    },
    {
      caption: "Trade size increment",
      content: "0.01",
    },
    {
      caption: "Trade size increment",
      content: "0.01",
    },
  ],
  [
    {
      content: "Max. Total Trade Size (Lots)",
    },
    {
      caption: "Max. Total Trade Size (Lots)",
      content: "1 000",
    },
    {
      caption: "Max. Total Trade Size (Lots)",
      content: "1 000",
    },
    {
      caption: "Max. Total Trade Size (Lots)",
      content: "1 000",
    },
    {
      caption: "Max. Total Trade Size (Lots)",
      content: "1 000",
    },
  ],
  [
    {
      content: "Margin Call",
    },
    {
      caption: "Margin Call",
      content: "40%",
    },
    {
      caption: "Margin Call",
      content: "50%",
    },
    {
      caption: "Margin Call",
      content: "50%",
    },
    {
      caption: "Margin Call",
      content: "50%",
    },
  ],
  [
    {
      content: "Stop Out Level",
    },
    {
      caption: "Stop Out Level",
      content: "10%",
    },
    {
      caption: "Stop Out Level",
      content: "20%",
    },
    {
      caption: "Stop Out Level",
      content: "20%",
    },
    {
      caption: "Stop Out Level",
      content: "20%",
    },
  ],
  [
    {
      content: "Telephone Trading",
    },
    {
      caption: "Telephone Trading",
      content: <PlusIcon />,
    },
    {
      caption: "Telephone Trading",
      content: <PlusIcon />,
    },
    {
      caption: "Telephone Trading",
      content: <PlusIcon />,
    },
    {
      caption: "Telephone Trading",
      content: <PlusIcon />,
    },
  ],
  [
    {
      content: "Account Currency",
    },
    {
      caption: "Account Currency",
      content: "USD, EUR, GBP, BTC",
    },
    {
      caption: "Account Currency",
      content: "USD, EUR, GBP, BTC",
    },
    {
      caption: "Account Currency",
      content: "USD, EUR, GBP, BTC",
    },
    {
      caption: "Account Currency",
      content: "USD, EUR, GBP, BTC",
    },
  ],
  [
    {
      content: "Commission",
    },
    {
      caption: "Commission",
      content: "$7.00 / Lot",
    },
    {
      caption: "Commission",
      content: "$ 0.00",
    },
    {
      caption: "Commission",
      content: "$9.00",
    },
    {
      caption: "Commission",
      content: "$7.00 / Lot*",
    },
  ],
  [
    {},
    {
      content: (
        <Button
          className={scss.button}
          buttonType="link"
          href="https://my.act4fx.net/en/register"
          type="default"
        >
          sign up
        </Button>
      ),
    },
    {
      content: (
        <Button
          className={scss.button}
          buttonType="link"
          href="https://my.act4fx.net/en/register"
          type="default"
        >
          sign up
        </Button>
      ),
    },
    {
      content: (
        <Button
          className={scss.button}
          buttonType="link"
          href="https://my.act4fx.net/en/register"
          type="default"
        >
          sign up
        </Button>
      ),
    },
    {
      content: (
        <Button
          className={scss.button}
          buttonType="link"
          href="https://my.act4fx.net/en/register"
          type="default"
        >
          sign up
        </Button>
      ),
    },
  ],
];
