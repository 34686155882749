import cn from "classnames";
import { ReactNode } from "react";
import scss from "./styles.module.scss";

type Props = {
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "p";
  children: ReactNode;
  className?: string;
  uppercase?: boolean;
};
const Typography = (props: Props) => {
  return (
    <props.level
      className={cn(
        scss.typography,
        { [scss.uppercase]: props.uppercase },
        props.className
      )}
    >
      {props.children}
    </props.level>
  );
};

Typography.defaultProps = {
  level: "p",
};
export default Typography;
