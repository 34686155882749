import React from "react";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Typography from "../../../../components/Typography/Typography";
import scss from "./styles.module.scss";

const SwapRatesBottomSection = () => {
  return (
    <LayoutSection
      contentClassName={scss.layoutSectionContent}
      sectionClassName={scss.layoutSection}
    >
      <div className={scss.contentInnerWrapper}>
        <Typography level={"h2"}>OVERNIGHT SWAP RATES</Typography>
        <Typography className={scss.boldText}>
          You can easily access the latest swap rates from within your
          MetaTrader 5 trading terminal by following these steps:
        </Typography>
        <Typography className={scss.text}>
          1. Right-click on any instrument in the 'Market Watch' section.
        </Typography>
        <Typography className={scss.text}>
          2. Select the 'Specification' option from the dropdown menu.
        </Typography>
        <Typography className={scss.text}>
          3. A new window will open, displaying the long and short swap rates
          for the selected currency pair.
        </Typography>
      </div>
    </LayoutSection>
  );
};

export default SwapRatesBottomSection;
