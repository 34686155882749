import { memo, SVGProps } from "react";

const DashLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="340"
      height="340"
      viewBox="0 0 340 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_152_2773)">
        <path
          d="M170 340C263.888 340 340 263.888 340 170C340 76.1116 263.888 0 170 0C76.1116 0 0 76.1116 0 170C0 263.888 76.1116 340 170 340Z"
          fill="#008CE7"
        />
        <path
          d="M202.789 85.0425H125.481L119.085 120.817L188.806 120.923C223.136 120.923 233.325 133.386 232.996 154.073C232.847 164.677 228.225 182.612 226.27 188.392C220.989 203.904 210.109 221.563 169.331 221.51L101.564 221.467L95.1681 257.284H172.274C199.463 257.284 211.044 254.107 223.274 248.466C250.41 235.949 266.571 209.142 273.062 174.154C282.689 122.092 270.682 85.0425 202.778 85.0425H202.789Z"
          fill="white"
        />
        <path
          d="M167.949 167.854C170.467 157.388 171.264 153.191 171.264 153.191H92.1506C71.9206 153.191 69.0306 166.366 67.1075 174.335C64.5894 184.758 63.7925 189.008 63.7925 189.008H142.917C163.136 189.008 166.026 175.833 167.949 167.854Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_2773">
          <rect width="340" height="340" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(DashLogo);
