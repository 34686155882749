import React from "react";
import scss from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  title: string;
  maxWidth?: number;
  size?: "large" | "medium";
  className?: string;
}

const SectionTitle = ({
  title,
  maxWidth,
  className,
  size = "large",
}: IProps) => {
  return (
    <h2 className={cn(scss.title, scss[size], className)} style={{ maxWidth }}>
      {title}
    </h2>
  );
};

export default SectionTitle;
