import React from "react";
import scss from "./styles.module.scss";
import Button from "../shared/Button/Button";
import EmailIcon from "../../icons/EmailIcon";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import Logo from "../../assets/images/Logo.svg";

const Footer = () => {
  return (
    <footer className={scss.footerWrapper}>
      <div className={scss.footerLayout}>
        <div className={cn(scss.footerSection, scss.logoSection)}>
          <div className={scss.logoAndContactsWrapper}>
            <NavLink className={scss.logoWrapper} to="/">
              <img src={Logo} alt="logo" />
            </NavLink>
            <div className={scss.contactsInfo}>
              <address className={scss.addressInfo}>
                Act 4 Financial Ltd
                <br />
                Registered Address: Hamilton Reserve Plaza, Building #1, Suite
                102, P.O. Box 590, Nevis, West Indies.
                <br />
                Registration Number: 21964
                <br />
                Registered Office Address: 56 Gorski Patnik Str., fl. 2, office
                4, 1421 residential area Lozenets, Sofia, Bulgaria.
                <br />
                Corporate email:{" "}
                <a href="mailto:support@act4fx.net" className={scss.email}>
                  support@act4fx.net
                </a>
              </address>
              <a className={scss.phoneNumber} href="tel:+17582857447">
                +359885076292
              </a>
            </div>
          </div>
          <div className={scss.actionsWrapper}>
            <Button
              type="outlineAccent"
              className={scss.actionButton}
              buttonType="link"
              href="https://my.act4fx.net/en/register"
            >
              demo version
            </Button>
            <Button
              type="outlineAccent"
              className={scss.actionButton}
              buttonType="link"
              href="https://my.act4fx.net/en/register"
            >
              sign up
            </Button>
            <Button
              type="default"
              className={scss.actionButton}
              buttonType="link"
              href="https://my.act4fx.net"
            >
              sign in
            </Button>
          </div>
          <div className={scss.innerLinksWrapper}>
            <Button
              buttonType="innerLink"
              to="/aml-policy"
              type="outFilledAccent"
              className={scss.innerLink}
            >
              AML Policy
            </Button>
            <Button
              buttonType="innerLink"
              to="/terms-and-conditions"
              type="outFilledAccent"
              className={scss.innerLink}
            >
              Terms and Conditions
            </Button>
            <Button
              buttonType="innerLink"
              to="/privacy-policy"
              type="outFilledAccent"
              className={scss.innerLink}
            >
              Privacy Policy
            </Button>
            <Button
              buttonType="innerLink"
              to="/costumer-agreement"
              type="outFilledAccent"
              className={scss.innerLink}
            >
              User Agreement
            </Button>
          </div>
        </div>
        <div className={scss.footerSection}>
          <p className={scss.warningInfo}>
            <b>Risk Warning</b>: Trading Leveraged Products such as Forex and
            Derivatives may not be suitable for all investors as they carry a
            high degree of risk to your capital. Please ensure that you fully
            understand the risks involved, taking into account your investments
            objectives and level of experience, before trading, and if
            necessary, seek independent advice. Please read the full Risk
            Disclosure. Act 4 Financial Ltd does not provide services to
            residents of the USA, Canada, Sudan, Syria, North Korea, Iran,
            Israel, New Zealand, Japan, Iraq, Mauritius, Myanmar, Yemen,
            Afghanistan, United Kingdom, Australia, Vanuatu and EEA countries.
          </p>
        </div>
        <div className={cn(scss.footerSection, scss.bottomSection)}>
          <Button
            className={scss.mailToButton}
            type="outFilled"
            buttonType="link"
            href="mailto:support@act4fx.net"
          >
            <EmailIcon color="var(--main)" />
            support@act4fx.net
          </Button>
          <p
            className={scss.footerTitle}
          >{`Copyright © ${new Date()?.getFullYear()} act4fx.net - All Rights Reserved.`}</p>
        </div>
      </div>
    </footer>
  );
};

export default observer(Footer);
