import React from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

interface IProps {
  title: string;
  maxWidth?: number;
  marginBottom?: number;
  className?: string;
}

const SectionDescription = ({
  title,
  maxWidth,
  marginBottom,
  className,
}: IProps) => {
  return (
    <p
      className={cn(scss.description, className)}
      style={{ maxWidth, marginBottom }}
    >
      {title}
    </p>
  );
};

export default SectionDescription;
