import { memo, SVGProps } from "react";

const Text370 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="592"
      height="180"
      viewBox="0 0 592 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M66.2348 178.557C53.505 178.557 42.1675 176.379 32.2223 172.023C22.334 167.61 14.5199 161.556 8.78016 153.861C3.09721 146.11 0.170488 137.171 0 127.044H37.1665C37.3938 131.287 38.7861 135.021 41.3435 138.246C43.9576 141.414 47.4242 143.876 51.7433 145.629C56.0623 147.383 60.9212 148.26 66.32 148.26C71.9461 148.26 76.9187 147.27 81.2378 145.29C85.5568 143.31 88.9382 140.566 91.3818 137.058C93.8255 133.55 95.0473 129.505 95.0473 124.922C95.0473 120.283 93.7402 116.181 91.1261 112.617C88.5688 108.996 84.8748 106.167 80.0443 104.13C75.2707 102.093 69.5877 101.075 62.9955 101.075H46.7138V74.0877H62.9955C68.5648 74.0877 73.4805 73.1259 77.7428 71.2023C82.0618 69.2786 85.4147 66.6195 87.8016 63.2249C90.1884 59.7737 91.3818 55.7567 91.3818 51.174C91.3818 46.8175 90.3305 42.9986 88.2278 39.7171C86.1819 36.3791 83.2836 33.7765 79.5329 31.9095C75.839 30.0424 71.5199 29.1089 66.5758 29.1089C61.5748 29.1089 57 30.0141 52.8514 31.8246C48.7029 33.5785 45.3783 36.0962 42.8778 39.3777C40.3774 42.6591 39.0419 46.5064 38.8714 50.9194H3.49501C3.6655 40.9052 6.53539 32.0792 12.1047 24.4413C17.674 16.8034 25.1755 10.8345 34.6092 6.53465C44.0997 2.17822 54.812 0 66.7462 0C78.7941 0 89.336 2.17822 98.3719 6.53465C107.408 10.8911 114.426 16.7751 119.427 24.1867C124.485 31.5417 126.986 39.802 126.929 48.9675C126.986 58.6987 123.945 66.8175 117.808 73.3239C111.727 79.8303 103.799 83.9604 94.0244 85.7143V87.0721C106.868 88.7129 116.643 93.1542 123.348 100.396C130.111 107.581 133.464 116.577 133.407 127.383C133.464 137.284 130.594 146.082 124.798 153.777C119.058 161.471 111.13 167.525 101.014 171.938C90.8988 176.351 79.3056 178.557 66.2348 178.557Z"
        fill="url(#paint0_linear_70_310)"
      />
      <path
        d="M166.844 176.181L239.217 33.6068V32.4187H154.91V2.37624H277.406V32.843L204.948 176.181H166.844Z"
        fill="url(#paint1_linear_70_310)"
      />
      <path
        d="M367.403 180C352.741 179.943 340.125 176.351 329.554 169.222C319.041 162.093 310.943 151.768 305.26 138.246C299.633 124.724 296.849 108.458 296.906 89.4484C296.906 70.495 299.719 54.3423 305.345 40.9901C311.028 27.6379 319.126 17.4823 329.639 10.5233C340.21 3.50778 352.798 0 367.403 0C382.008 0 394.567 3.50778 405.081 10.5233C415.651 17.5389 423.778 27.7228 429.46 41.075C435.143 54.3706 437.956 70.495 437.9 89.4484C437.9 108.515 435.058 124.809 429.375 138.331C423.749 151.853 415.679 162.178 405.166 169.307C394.652 176.436 382.065 180 367.403 180ZM367.403 149.533C377.405 149.533 385.389 144.526 391.356 134.512C397.323 124.498 400.279 109.477 400.222 89.4484C400.222 76.2659 398.858 65.29 396.13 56.5205C393.459 47.7511 389.651 41.1598 384.707 36.7468C379.82 32.3338 374.052 30.1273 367.403 30.1273C357.458 30.1273 349.501 35.0778 343.534 44.9788C337.567 54.8798 334.555 69.703 334.498 89.4484C334.498 102.801 335.834 113.946 338.505 122.885C341.233 131.768 345.069 138.444 350.013 142.914C354.957 147.327 360.754 149.533 367.403 149.533Z"
        fill="url(#paint2_linear_70_310)"
      />
      <path
        d="M516.388 149.703V29.5332H546.906V149.703H516.388ZM471.294 104.809V74.4272H592V104.809H471.294Z"
        fill="url(#paint3_linear_70_310)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_70_310"
          x1="-3.50001"
          y1="90"
          x2="377.5"
          y2="90"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.30981" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_70_310"
          x1="-3.50001"
          y1="90"
          x2="377.5"
          y2="90"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.30981" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_70_310"
          x1="-3.50001"
          y1="90"
          x2="377.5"
          y2="90"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.30981" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_70_310"
          x1="-3.50001"
          y1="90"
          x2="377.5"
          y2="90"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.30981" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default memo(Text370);
