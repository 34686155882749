import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import sidebarStore from "../../store/SidebarStore";
import BurgerMenu from "./BurgerMenu";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  openHandler?: (filled: boolean) => void;
};

const BurgerButton = ({ openHandler }: Props) => {
  const isOpen = sidebarStore.state.opened;

  useEffect(() => {
    return () => sidebarStore.closeMenu();
  }, []);

  const openBurgerMenuHandler = () => {
    // isOpen ? sidebarStore.closeMenu() : sidebarStore.openMenu(<BurgerMenu />);
    if (isOpen) {
      sidebarStore.closeMenu();
      if (openHandler) {
        openHandler(false);
      }
    } else {
      sidebarStore.openMenu(<BurgerMenu />);
      if (openHandler) {
        openHandler(true);
      }
    }
  };

  return (
    <>
      <div
        onClick={openBurgerMenuHandler}
        className={cn(scss.burgerIcon, { [scss.burgerIconOpen]: isOpen })}
      >
        <span className={scss.top} />
        <span className={scss.middle} />
        <span className={scss.bottom} />
      </div>
    </>
  );
};

export default observer(BurgerButton);
