import React from "react";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Typography from "../../../../components/Typography/Typography";
import Separator from "../../../../components/shared/Separator/Separator";
import scss from "./styles.module.scss";

const SwapRatesAboutSection = () => {
  return (
    <>
      <LayoutSection
        contentClassName={scss.layoutSectionContent}
        sectionClassName={scss.sectionLayout}
      >
        <Typography className={scss.title} level="h2" uppercase>
          Understanding Forex Swap Rates
        </Typography>
        <Typography className={scss.text}>
          Forex swap rates, also known as rollover rates, refer to the overnight
          interest that is added or deducted when holding a position open
          overnight. These rates are determined by the interest rate
          differential between the two currencies involved in the currency pair
          and whether the position is long (buy) or short (sell).
        </Typography>
        <Separator type="horizontal" color="var(--grey-1)" />
        <Typography className={scss.subtitle} level="h3" uppercase>
          Key Points about Swap Rates
        </Typography>
      </LayoutSection>
      <LayoutSection
        contentClassName={scss.bottomSection}
        sectionClassName={scss.bottomSectionLayout}
        hideMobilePadding
      >
        {cards.map((item, i) => (
          <div className={scss.card} key={i}>
            {item}
          </div>
        ))}
      </LayoutSection>
    </>
  );
};

export default SwapRatesAboutSection;

const cards = [
  "Swap rates are applied only when positions are held open until the next forex trading day.",
  "Certain currency pairs may have negative swap rates for both 'long' and 'short' positions.",
  "Each currency pair has its own specific swap charge, calculated based on a standard size of 1.0 lots.",
  "Swap rates are measured in points, and MetaTrader 5 automatically converts them into the base currency of your account.",
];
