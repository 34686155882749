import React from "react";
import { PlatformCardDto, TradeCurrenciesDto } from "../../../../api/contracts";
import SectionCaption from "../../../../components/shared/SectionCaption/SectionCaption";
import SectionTitle from "../../../../components/shared/SectionTitle/SectionTitle";
import SectionDescription from "../../../../components/shared/SectionDescription/SectionDescription";
import TradeCurrencies from "../HeadingSection/components/TradeCurrencies/TradeCurrencies";
import Separator from "../../../../components/shared/Separator/Separator";
import Button from "../../../../components/shared/Button/Button";
import scss from "./styles.module.scss";

interface IProps {
  platformCard: PlatformCardDto;
  currencies: TradeCurrenciesDto;
}

const PlatformSection = ({ platformCard, currencies }: IProps) => {
  return (
    <div id="platforms" className={scss.platformChoiceSectionWrapper}>
      <div className={scss.sectionLayout}>
        <SectionCaption title="MOST POPULAR TRADE CURRENCY:" />
        <TradeCurrencies currencies={currencies} />
        <Separator
          type="horizontal"
          wrapperClassName={scss.separator}
          color="var(--light)"
        />
        <div className={scss.platformWrapper}>
          <div className={scss.platformInfoWrapper}>
            <SectionCaption title="OUR TRADING PLATFORM" />
            <SectionTitle title="METATRADER 5" size="medium" />
            <SectionDescription
              className={scss.platformDescription}
              title="Experience the industry-leading platform for CFD trading, supporting automated, manual, and social trading strategies. Stay focused on the market while we handle the rest."
            />
            <div className={scss.platformActionsWrapper}>
              <Button
                buttonType="link"
                type="outline"
                href={platformCard.downloadSrc}
                size="m"
                horizontalPaddings="paddingXL"
              >
                {platformCard.buttonTitle}
              </Button>
              <div className={scss.platformInfo}>
                <div className={scss.platformLogoWrapper}>
                  {platformCard.icon}
                </div>
                <p className={scss.platformText}>
                  This platform use the Act4 FX system
                </p>
              </div>
            </div>
          </div>
          <div className={scss.imageWrapper}>
            <img src="/assets/mt5.webp" alt="metatrader5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformSection;
