import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ContactUs from "../Main/components/ContactUs/ContactUs";
import LayoutSection from "../../components/Layout/LayoutSection/LayoutSection";
import scss from "./styles.module.scss";

const TermsAndConditions = () => {
  return (
    <div>
      <LayoutSection
        sectionClassName={scss.pageHeaderBlock}
        contentClassName={scss.sectionContentWrapper}
        style={{
          backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
        }}
      >
        <Breadcrumbs position="left" title="Terms and Conditions" />
        <h1 className={scss.pageTitle}>TERMS AND CONDITIONS</h1>
      </LayoutSection>
      <div className={scss.contentWrapper}>
        <div className={scss.textItemWrapper}>
          <p className={scss.textItemDescription}>
            This Agreement ("Terms", "Agreement") establishes the terms and
            conditions between Act 4 Financial Ltd. ("Act 4 Financial Ltd.",
            "us", "we", or "our") and you ("User", "you", or "your"). It
            outlines the general terms and conditions for your use of the
            http://www.act4fx.net website and its products or services
            (collectively referred to as "Website" or "Services").
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Information Accuracy</h4>
          <p className={scss.textItemDescription}>
            Occasionally, there may be errors, inaccuracies, or omissions in the
            information provided on the Website regarding product descriptions,
            availability, promotions, and offers. We reserve the right to
            correct any errors, inaccuracies, or omissions, and to modify or
            update information or cancel orders if any information on the
            Website or any related Service is found to be inaccurate, without
            prior notice (even after you have placed your order). We are not
            obligated to update, amend, or clarify information on the Website,
            including pricing information, except as required by law. The
            absence of a specified update or refresh date on the Website does
            not imply that all information on the Website or any related Service
            has been modified or updated.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Service Uptime Guarantee</h4>
          <p className={scss.textItemDescription}>
            We guarantee that our service will be available 99% of the time per
            month. However, this guarantee does not cover service interruptions
            caused by: (1) scheduled maintenance or repairs that we may conduct
            periodically; (2) interruptions caused by you or your activities;
            (3) outages that do not affect the core functionality of the
            Service; (4) causes beyond our control or that are not reasonably
            foreseeable; and (5) outages related to the reliability of certain
            programming environments.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Data Backups</h4>
          <p className={scss.textItemDescription}>
            We are not responsible for any Content stored on the Website, and we
            cannot be held liable for any loss of Content. It is solely your
            responsibility to maintain appropriate backups of your Content.
            However, in some cases, we may, without any obligation, be able to
            restore some or all of your deleted data from a specific date and
            time when we may have backed up the data for our own purposes. We do
            not guarantee the availability of the data you need.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Prohibited Uses</h4>
          <p className={scss.textItemDescription}>
            In addition to other terms stated in the Agreement, the following
            uses of the Website or its Content are prohibited: (a) engaging in
            any unlawful purpose; (b) soliciting others to perform or
            participate in unlawful acts; (c) violating international, federal,
            provincial, or state regulations, rules, laws, or local ordinances;
            (d) infringing upon our intellectual property rights or the
            intellectual property rights of others; (e) harassing, abusing,
            insulting, harming, defaming, slandering, disparaging, intimidating,
            or discriminating against individuals based on gender, sexual
            orientation, religion, ethnicity, race, age, national origin, or
            disability; (f) submitting false or misleading information; (g)
            uploading or transmitting viruses or any other type of malicious
            code that may affect the functionality or operation of the Service,
            related websites, or the Internet; (h) collecting or tracking
            personal information of others; (i) spamming, phishing, pharming,
            pretexting, spidering, crawling, or scraping; (j) engaging in any
            obscene or immoral purpose; or (k) interfering with or circumventing
            the security features of the Service or any related website, other
            websites, or the Internet. We reserve the right to terminate your
            use of the Service or any related website if you violate any of
            these prohibited uses.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Intellectual Property Rights</h4>
          <p className={scss.textItemDescription}>
            This Agreement does not transfer any Act4 LLC. or third-party
            intellectual property rights from Act 4 Financial Ltd. to you. Act 4
            Financial Ltd. retains all right, title, and interest in and to such
            property. The trademarks, service marks, graphics, and logos used in
            connection with our Website or Services are either trademarks or
            registered trademarks of Act 4 Financial Ltd. or its licensors.
            Other trademarks, service marks, graphics, and logos used in
            connection with our Website or Services may be the trademarks of
            other third parties. Your use of our Website and Services does not
            grant you any rights or license to use any Act 4 Financial Ltd. or
            third-party trademarks.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Disclaimer of Warranty</h4>
          <p className={scss.textItemDescription}>
            By using our Website or Services, you agree that you do so at your
            own risk. The Service is provided on an "as is" and "as available"
            basis. We expressly disclaim all warranties, whether express or
            implied, including but not limited to the warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement. We make no warranty that the Services will meet
            your requirements or that the Service will be uninterrupted, timely,
            secure, or error-free. We do not guarantee the accuracy or
            reliability of any information obtained through the Service, nor do
            we guarantee that any defects in the Service will be corrected. You
            understand and agree that any material or data downloaded or
            obtained through the use of the Service is done at your own
            discretion and risk, and you are solely responsible for any damages
            to your computer system or loss of data that may result from such
            download. We make no warranty regarding any goods or services
            purchased or obtained through the Service or any transactions
            entered into through the Service. Any advice or information obtained
            from us or through the Service does not create any warranty not
            expressly stated in this Agreement.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Limitation of Liability</h4>
          <p className={scss.textItemDescription}>
            To the fullest extent permitted by applicable law, Act 4 Financial
            Ltd., its affiliates, officers, directors, employees, agents,
            suppliers, or licensors shall not be liable to any person for any
            indirect, incidental, special, punitive, cover, or consequential
            damages, including but not limited to damages for lost profits,
            revenue, sales, goodwill, use or content, impact on business,
            business interruption, loss of anticipated savings, loss of business
            opportunity, or any other damages, whether in contract, tort
            (including negligence), warranty, breach of statutory duty, or
            otherwise, even if Act 4 Financial Ltd. has been advised of the
            possibility of such damages or could have foreseen such damages.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Indemnification</h4>
          <p className={scss.textItemDescription}>
            You agree to indemnify and hold Act 4 Financial Ltd. and its
            affiliates, directors, officers, employees, and agents harmless from
            any liabilities, losses, damages, or costs, including reasonable
            attorneys' fees, incurred in connection with or arising from any
            third-party allegations, claims, actions, disputes, or demands
            asserted against them as a result of or relating to your Content,
            your use of the Website or Services, or any willful misconduct on
            your part.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Severability</h4>
          <p className={scss.textItemDescription}>
            All rights and restrictions in this Agreement shall be exercised and
            applicable only to the extent that they do not violate any
            applicable laws and are intended to be limited to the extent
            necessary to avoid rendering this Agreement illegal, invalid, or
            unenforceable. If any provision or portion of any provision in this
            Agreement is deemed illegal, invalid, or unenforceable by a court of
            competent jurisdiction, the parties intend that the remaining
            provisions or portions thereof shall constitute their agreement with
            respect to the subject matter hereof, and all such remaining
            provisions or portions shall remain in full force and effect.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Dispute Resolution</h4>
          <p className={scss.textItemDescription}>
            The formation, interpretation, and performance of this Agreement, as
            well as any disputes arising out of it, shall be governed by the
            substantive and procedural laws of Saint Lucia, without regard to
            its rules on conflicts or choice of law. The exclusive jurisdiction
            and venue for any actions related to the subject matter of this
            Agreement shall be the state and federal courts located in Saint
            Lucia, and you hereby submit to the personal jurisdiction of such
            courts. By agreeing to this Agreement, you waive any right to a jury
            trial in any proceeding arising out of or related to this Agreement.
            The United Nations Convention on Contracts for the International
            Sale of Goods does not apply to this Agreement.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Assignment</h4>
          <p className={scss.textItemDescription}>
            You may not assign, resell, sublicense, or otherwise transfer or
            delegate any of your rights or obligations under this Agreement, in
            whole or in part, without our prior written consent, which consent
            shall be at our sole discretion and without obligation. Any such
            assignment or transfer without consent shall be null and void. We,
            on the other hand, are free to assign any of our rights or
            obligations under this Agreement, in whole or in part, to any third
            party as part of the sale of all or substantially all of our assets
            or stock or as part of a merger.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Changes and Amendments</h4>
          <p className={scss.textItemDescription}>
            We reserve the right to modify this Agreement or its policies
            relating to the Website or Services at any time, effective upon
            posting an updated version of this Agreement on the Website. When we
            make such changes, we will post a notification on the main page of
            our Website. Your continued use of the Website after any such
            changes are made constitutes your consent to the updated Agreement.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Acceptance of These Terms</h4>
          <p className={scss.textItemDescription}>
            By using the Website or its Services, you acknowledge that you have
            read this Agreement and agree to all its terms and conditions. You
            agree to be bound by this Agreement and abide by its terms. If you
            do not agree to the terms of this Agreement, you are not authorized
            to use or access the Website and its Services.
          </p>
        </div>
      </div>
      <ContactUs />
    </div>
  );
};

export default TermsAndConditions;
