import { memo } from "react";

const WarningIcon = () => {
  return (
    <svg
      width="4"
      height="14"
      viewBox="0 0 4 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 14V11.6667H4V14H0ZM0 10.5V0H4V10.5H0Z" fill="#3A4F50" />
    </svg>
  );
};

export default memo(WarningIcon);
