import React from "react";
import { TradeCurrenciesDto } from "../../../../../../api/contracts";
import cn from "classnames";
import Separator from "../../../../../../components/shared/Separator/Separator";
import scss from "./styles.module.scss";

interface IProps {
  title?: string;
  currencies: TradeCurrenciesDto;
  classname?: string;
}

const TradeCurrencies = ({ currencies, title, classname }: IProps) => {
  return (
    <div className={cn(scss.tradeCurrenciesContainer, classname)}>
      {title && <p className={scss.currenciesTitle}>{title}</p>}
      <div className={scss.tradeCurrenciesWrapper}>
        {currencies.map((item, i) => (
          <div className={scss.currencyItem} key={i}>
            <span>{item.from}</span>
            <Separator type="vertical" />
            <span>{item.to}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TradeCurrencies;
