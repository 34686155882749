import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/shared/Button/Button";
import scss from "./styles.module.scss";

const HeaderSection = () => {
  return (
    <LayoutSection
      style={{
        backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
      }}
      sectionClassName={scss.section}
      contentClassName={scss.content}
    >
      <Breadcrumbs position="left" title="Portfolio Diversification" />
      <div className={scss.grid}>
        <Typography level="h1" className={scss.gridTitle}>
          Portfolio Diversification
        </Typography>
        <div className={scss.gridContent}>
          <Typography level="h4" className={scss.caption}>
            Explore a wide range of financial trading instruments.
          </Typography>
          <Typography level="p" className={scss.description}>
            Our platform offers a variety of trading instruments, including
            leveraged products such as Forex. With our extensive selection of
            currency pairs, you can enhance your trading experience and
            diversify your portfolio. We continuously expand our list of trading
            instruments, ensuring that you have access to new opportunities and
            the ability to trade in any market environment.
          </Typography>
          <Button
            className={scss.terms}
            buttonType="innerLink"
            to="/terms-and-conditions"
            type="outFilled"
            size="xs"
            upperCase={false}
          >
            Terms and Conditions Apply
          </Button>
          <div className={scss.buttons}>
            <Button
              className={scss.button}
              buttonType="link"
              href="https://my.act4fx.net/en/register"
              type="default"
              size="xl"
            >
              OPEN AN ACCOUNT
            </Button>
            <Button
              className={scss.button}
              buttonType="link"
              href="https://my.act4fx.net/en/register"
              type="outlineAccent"
              size="xl"
            >
              DEMO ACCOUNT
            </Button>
          </div>
        </div>
        <div className={scss.gridImage}>
          <img src="/assets/370-image.webp" alt="Portfolio Diversification" />
        </div>
      </div>
    </LayoutSection>
  );
};

export default HeaderSection;
