import React from "react";
import SectionTitle from "../../../../components/shared/SectionTitle/SectionTitle";
import SectionDescription from "../../../../components/shared/SectionDescription/SectionDescription";
import Button from "../../../../components/shared/Button/Button";
import Separator from "../../../../components/shared/Separator/Separator";
import scss from "./styles.module.scss";

const ContactUs = () => {
  return (
    <div id="contactUs" className={scss.contactUsSectionWrapper}>
      <div className={scss.sectionLayout}>
        <Separator
          type="horizontal"
          color="var(--light)"
          className={scss.separator}
        />
        <div className={scss.sectionLayoutInner}>
          <div className={scss.contactUsSectionLeft}>
            <SectionTitle
              title="Start your trading journey by creating an account today!"
              size={"medium"}
              maxWidth={568}
            />
            <SectionDescription
              title="Join us and start trading on one of the most transparent and user-friendly trading platforms available. Sign up now!"
              maxWidth={472}
              marginBottom={40}
            />
            <Button
              className={scss.contactUsLInk}
              size="xl"
              type="default"
              horizontalPaddings="paddingL"
              buttonType="link"
              href="https://my.act4fx.net/en/register"
            >
              Create account
            </Button>
          </div>
          <div className={scss.contactUsSectionRight}>
            <h5 className={scss.contactUsSectionRightCaption}>
              Need assistance?
            </h5>
            <h3 className={scss.contactUsSectionRightTitle}>
              Reach out to us for answers to your inquiries.
            </h3>
            <p className={scss.contactUsSectionDescription}>
              Our manager will be in touch with you promptly to address your
              questions.
            </p>
            <Button
              className={scss.contactUsLInk}
              size="xl"
              type="outline"
              horizontalPaddings="paddingXL"
              buttonType="link"
              href="mailto:support@act4fx.net"
            >
              contact us
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
