import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import LayoutSection from "../../components/Layout/LayoutSection/LayoutSection";
import scss from "./styles.module.scss";

const CustomerAgreement = () => {
  return (
    <div>
      <LayoutSection
        sectionClassName={scss.pageHeaderBlock}
        contentClassName={scss.sectionContentWrapper}
        style={{
          backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
        }}
      >
        <Breadcrumbs position="left" title="Customer Agreement" />
        <h1 className={scss.pageTitle}>CUSTOMER AGREEMENT</h1>
      </LayoutSection>
      <div className={scss.contentWrapper}>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>TERMINATION</h4>
          <ul className={scss.textItemList}>
            <li className={scss.textItemListItem}>
              Without limiting our rights to take the action specified in clause
            </li>
            <li className={scss.textItemListItem}>
              The effects on our other rights in this Agreement. Additionally,
              any indemnity provided by you in this Agreement remains valid
              after termination. Upon termination of this Agreement, without
              limiting our other rights in this Agreement, we may perform one or
              more of the following actions: (a) close your Account; (b) settle
              any Transaction that has not yet been settled at the time of
              termination; (c) enter into one or more Transactions to liquidate
              any unsettled Transactions or Open Positions (and determine the
              value at which the Transaction or Transactions will be
              liquidated); (d) cancel any Orders; (e) take any appropriate
              action we consider necessary in the circumstances; (f) exercise
              any other rights we have under this Agreement.
            </li>
            <li className={scss.textItemListItem}>
              We are not responsible to you for any loss, expense, cost, or
              liability incurred due to any failure of the Platform,
              interruptions in data or services, transmission failures or
              delays, or similar technical errors arising from or in connection
              with the use, operation, performance, and/or errors or
              malfunctions of the Platform, except in cases of our fraud,
              willful default, or negligence. Additionally, we are not liable to
              you for any Profit or Loss removal resulting from quotes errors
              caused by our typographical errors, feed errors, or incorrect
              perception of information entered into the system by you, except
              in cases of our fraud, willful default, or negligence. We have the
              right to make necessary corrections in your Account based on the
              market value of the relevant Underlying Asset at the time an error
              occurs. The price of your Contract may change between the initial
              offer and the receipt of your Order due to transmission delays
              between you and us. If we offer automatic Order execution, we are
              entitled to change the execution price to the market value at the
              time we receive the Order from you. Our Platform may have
              different versions with varying features, including security
              levels and available products and services. We are not responsible
              for any loss, expense, cost, or liability incurred by using a
              different version of the Platform than our current standard
              version (with all available updates installed). You are
              responsible for all Orders and the accuracy of information
              transmitted via the Platform using your name, password, or any
              other means of personal identification used to identify you. You
              must keep all passwords confidential and ensure that no one else
              accesses your Account. You are liable to us for Contracts executed
              using your password, even if you did not authorize its use or if
              your Account is wrongfully accessed. Any Confirmation we send or
              make available to you on the Platform is considered our
              confirmation of a Contract, regardless of whether the Platform
              immediately confirms the execution of the Contract upon sending
              your instructions.
            </li>
          </ul>
          <p className={scss.textItemDescription}>
            ARBITRAGE: Internet connectivity delays and price feed errors can
            sometimes cause discrepancies between the displayed price on the
            Act4 FX online facility and the actual market rates. Act4 FX
            prohibits the practice of latency arbitrage on its trading platform.
            Transactions based on latency arbitrage may be revoked without prior
            notice. Act4 FX reserves the right to make any necessary adjustments
            or corrections to accounts involved without prior notice. You agree
            that any dispute arising from such execution or quoting errors will
            be resolved by Act4 FX at its sole and absolute discretion. Act4 FX
            is not obligated to inform you about suspected trades involving
            latency arbitrage or any subsequent corrections. You agree to
            indemnify and hold Act4 FX, its directors, employees, and agents
            harmless from any and all liabilities, losses, damages, costs, and
            expenses.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerAgreement;
