import Typography from "../../../../components/Typography/Typography";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import { CSSProperties, ReactNode } from "react";
import Button from "../../../../components/shared/Button/Button";
import scss from "./styles.module.scss";

type Props = {
  title: string;
  items: Array<{ title: ReactNode }>;
  links?: Array<{ content: ReactNode; link: string }>;
  columnsCount: number;
};
const Offers = (props: Props) => {
  const style = {
    "--grid-columns": props.columnsCount,
  } as CSSProperties;

  return (
    <LayoutSection contentClassName={scss.section}>
      <div className={scss.root}>
        <Typography level="h3" className={scss.title}>
          {props.title}
        </Typography>
        <div className={scss.grid} style={style}>
          {props.items?.map((item, i) => (
            <div key={i} className={scss.gridItem}>
              {item.title}
            </div>
          ))}
        </div>
        {!!props.links ? (
          <div className={scss.buttons}>
            {props.links.map((item, i) => (
              <Button
                key={i}
                buttonType="link"
                href={item.link}
                type={!i ? "default" : "outline"}
                horizontalPaddings="paddingXL"
                size="xl"
              >
                {item.content}
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </LayoutSection>
  );
};

export default Offers;
