import cn from "classnames";
import scss from "./styles.module.scss";
import Button from "../shared/Button/Button";

type Props = {
  position: "center" | "left";
  title: string;
};
const Breadcrumbs = ({ title, position = "left" }: Props) => {
  return (
    <div className={cn(scss.root, scss[position])}>
      <Button
        buttonType="innerLink"
        to="/"
        type="outFilled"
        size="xs"
        upperCase={false}
        className={scss.button}
      >
        Home
      </Button>
      <div className={scss.line}>-</div>
      <div className={scss.title}>{title}</div>
    </div>
  );
};

export default Breadcrumbs;
