import React from "react";
import scss from "./styles.module.scss";
import cn from "classnames";

type Vertical = {
  type: "vertical";
  height?: number;
};

type Horizontal = {
  type: "horizontal";
  width?: number;
};

type IProps = {
  strokeWidth?: number;
  color?: string;
  className?: string;
  wrapperClassName?: string;
} & (Vertical | Horizontal);

const Separator = (props: IProps) => {
  switch (props.type) {
    case "horizontal":
      return (
        <div
          className={cn(scss[props.type], props.wrapperClassName)}
          style={{ width: props.width }}
        >
          <div
            className={cn(scss.separator, props.className)}
            style={{ backgroundColor: props.color, height: props.strokeWidth }}
          />
        </div>
      );
    case "vertical":
      return (
        <div
          className={cn(scss[props.type], props.wrapperClassName)}
          style={{ height: props.height }}
        >
          <div
            className={cn(scss.separator, props.className)}
            style={{ backgroundColor: props.color, width: props.strokeWidth }}
          />
        </div>
      );
    default:
      return null;
  }
};

export default Separator;
