import { memo, SVGProps } from "react";

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.01 7.425L12.02 1.415L10.607 0L6.01 4.6L1.414 0L0 1.414L6.01 7.425Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ChevronDownIcon);
