import React, { useEffect, useRef } from "react";
import scss from "./styles.module.scss";
import cn from "classnames";
import BurgerNavRow from "./BurgerNavRow";
import Button from "../../../shared/Button/Button";
import { useLocation } from "react-router-dom";
import sidebarStore from "../../../../store/SidebarStore";

const HeaderNavBar = () => {
  const { pathname } = useLocation();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      sidebarStore.closeMenu();
    } else isMounted.current = true;
  }, [pathname]);

  return (
    <div>
      <BurgerNavRow title="Trading">
        <div className={scss.menu}>
          <div className={cn(scss.menuSection, scss.mainSection)}>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/account-comparisons"
              className={scss.menuLink}
            >
              Account Compraison
            </Button>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/swap-rates"
              className={scss.menuLink}
            >
              Swap Rates
            </Button>
          </div>
          <div className={scss.menuSection}>
            <p className={scss.menuTitle}>Markets</p>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/portfolio-diversification"
              className={scss.menuLink}
            >
              Portfolio Deversification
            </Button>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/trading-hours"
              className={scss.menuLink}
            >
              Trading Hours
            </Button>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/trading-conditions"
              className={scss.menuLink}
            >
              Trading Conditions
            </Button>
          </div>
        </div>
      </BurgerNavRow>
      <BurgerNavRow title="Our Platforms">
        <div className={scss.menu}>
          <div className={scss.menuSection}>
            <p className={scss.menuTitle}>MT5 Avaible Platfroms</p>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/mt5-web-terminal"
              className={scss.menuLink}
            >
              MT5 WebTrader
            </Button>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/mt5-terminal"
              className={scss.menuLink}
            >
              MT5 Terminal (Desktop)
            </Button>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/mt5-android"
              className={scss.menuLink}
            >
              MT5 Android
            </Button>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/mt5-iphone"
              className={scss.menuLink}
            >
              MT5 Iphone
            </Button>
            <Button
              size="s"
              buttonType={"innerLink"}
              type="outFilled"
              to="/mt5-ipad"
              className={scss.menuLink}
            >
              MT5 IPad
            </Button>
          </div>
        </div>
      </BurgerNavRow>
      <BurgerNavRow title="Partners & More">
        <div className={scss.menu}>
          <div className={scss.menuSection}>
            <Button
              size="s"
              buttonType={"link"}
              type="outFilled"
              href="/"
              className={scss.menuLink}
            >
              Partners Program
            </Button>
            <p className={scss.menuTitle}>Support & Resources</p>
            <Button
              size="s"
              buttonType={"link"}
              type="outFilled"
              href="/"
              className={scss.menuLink}
            >
              FAQ
            </Button>
            <Button
              size="s"
              buttonType={"link"}
              type="outFilled"
              href="/"
              className={scss.menuLink}
            >
              Customer Portal
            </Button>
            <Button
              size="s"
              buttonType={"link"}
              type="outFilled"
              href="/"
              className={scss.menuLink}
            >
              Contact US
            </Button>
          </div>
        </div>
      </BurgerNavRow>
      <BurgerNavRow title="Payments">
        <div className={scss.menu}>
          <div className={scss.menuSection}>
            <Button
              size="s"
              buttonType={"link"}
              type="outFilled"
              href="/payment-processing"
              className={scss.menuLink}
            >
              Deposit
            </Button>
            <Button
              size="s"
              buttonType={"link"}
              type="outFilled"
              href="/payment-processing?withdrawals"
              className={scss.menuLink}
            >
              Withdrawals
            </Button>
          </div>
        </div>
      </BurgerNavRow>
    </div>
  );
};

export default HeaderNavBar;
