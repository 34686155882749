import { CSSProperties, ReactNode } from "react";
import cn from "classnames";
import TextWithShadow from "../../../../components/shared/TextWithShadow/TextWithShadow";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../store/ResolutionStore";
import scss from "./styles.module.scss";

type Props = {
  instructions?: Array<
    Array<{
      content: ReactNode;
      header?: boolean;
      unite?: boolean;
      showNumber?: boolean;
    }>
  >;
};
const InstructionsGrid = (props: Props) => {
  const tablet =
    resolutionStore._resolution.tablet ||
    resolutionStore._resolution.phoneLg ||
    resolutionStore._resolution.phone;

  const style = {
    "--grid-columns": props.instructions?.length,
  } as CSSProperties;

  if (tablet) {
    return (
      <div className={scss.mobileGrid}>
        {props.instructions?.map((column, i) => (
          <div className={scss.mobileColumn} key={i}>
            {column.map((item, index) => (
              <Cell
                key={`${i}-${index}`}
                index={index}
                firstItem={!index}
                lastItem={!column[index + 1]}
                unite={item.unite}
                header={item.header}
                content={item.content}
                showNumber={item.showNumber}
              />
            ))}
          </div>
        ))}
      </div>
    );
  }

  const maxRows = props.instructions
    ? Math.max(...props.instructions?.map((inst) => inst.length))
    : 0;

  const list: any[] = [];
  for (let i = 0; i < maxRows; i++) {
    props.instructions?.forEach((itm, index) => {
      const item = itm[i];
      if (item) {
        list.push(
          <Cell
            key={`${i}-${index}`}
            index={i}
            firstItem={!i}
            lastItem={!itm[i + 1]}
            unite={item.unite}
            header={item.header}
            content={item.content}
            showNumber={item.showNumber}
          />
        );
      }
    });
  }
  return (
    <div className={scss.instructionsGrid} style={style}>
      {list}
    </div>
  );
};

type ItemProps = {
  content: ReactNode;
  header?: boolean;
  unite?: boolean;
  showNumber?: boolean;
  firstItem?: boolean;
  lastItem?: boolean;
  index: number;
};
const Cell = (props: ItemProps) => {
  return (
    <div
      className={cn(scss.instructionItem, {
        [scss.header]: props.header,
        [scss.firstItem]: props.firstItem,
        [scss.lastItem]: props.lastItem,
      })}
    >
      {props.header ? (
        <TextWithShadow
          gradientText={false}
          className={scss.headerTitle}
          title={props.content as unknown as string}
        />
      ) : (
        <div className={scss.instructionItemInner}>
          {props.showNumber ? (
            <span className={scss.counter}>0{props.index}</span>
          ) : null}
          {props.content}
        </div>
      )}
    </div>
  );
};

export default observer(InstructionsGrid);
