import { ReactNode } from "react";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/shared/Button/Button";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  breadCrumbs: string;
  title: string;
  subtitle: string;
  description: string;
  links: Array<{ link: string; title: string; icon?: ReactNode }>;
  image: string;
  className?: string;
};
const MT5Home = (props: Props) => {
  const style = {
    backgroundImage: `url(/assets/payment-processing-heading-bg.webp)`,
  };
  return (
    <LayoutSection
      sectionClassName={scss.section}
      contentClassName={cn(scss.sectionContent, scss.topContentSection)}
      style={style}
    >
      <img className={scss.deviceImage} src={props.image} alt="device" />
      <Breadcrumbs position="center" title={props.breadCrumbs} />
      <div className={scss.container}>
        <Typography level="h1" className={scss.title}>
          {props.title}
        </Typography>
        <Typography level="h3" className={scss.subtitle}>
          {props.subtitle}
        </Typography>
        <Typography level="h4" className={scss.description}>
          {props.description}
        </Typography>
        <div className={scss.buttons}>
          {props.links.map((item, i) => (
            <Button
              key={i}
              buttonType="link"
              type="default"
              horizontalPaddings="paddingM"
              iconAfter={item.icon}
              href={item.link}
            >
              {item.title}
            </Button>
          ))}
        </div>
      </div>
    </LayoutSection>
  );
};

export default MT5Home;
