import HomeSection from "./components/HomeSection/HomeSection";
import ConditionsSections from "./components/ConditionsSections/ConditionsSections";

const TradingConditions = () => {
  return (
    <main>
      <HomeSection />
      <ConditionsSections />
    </main>
  );
};

export default TradingConditions;
