import { memo, SVGProps } from "react";

const USDCoinLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="340"
      height="340"
      viewBox="0 0 340 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_152_2727)">
        <path
          d="M170 340C264.209 340 340 264.209 340 170C340 75.7911 264.209 0 170 0C75.7911 0 0 75.7911 0 170C0 264.209 75.7911 340 170 340Z"
          fill="#2775CA"
        />
        <path
          d="M216.75 196.916C216.75 172.125 201.875 163.625 172.125 160.084C150.875 157.25 146.625 151.584 146.625 141.666C146.625 131.748 153.709 125.375 167.875 125.375C180.625 125.375 187.709 129.625 191.25 140.25C191.959 142.375 194.084 143.791 196.209 143.791H207.541C210.375 143.791 212.5 141.666 212.5 138.834V138.125C209.666 122.541 196.916 110.5 180.625 109.084V92.0839C180.625 89.25 178.5 87.125 174.959 86.4161H164.334C161.5 86.4161 159.375 88.5411 158.666 92.0839V108.375C137.416 111.209 123.959 125.375 123.959 143.084C123.959 166.459 138.125 175.666 167.875 179.209C187.709 182.75 194.084 187 194.084 198.334C194.084 209.668 184.166 217.459 170.709 217.459C152.291 217.459 145.916 209.666 143.791 199.041C143.084 196.209 140.959 194.791 138.834 194.791H126.791C123.959 194.791 121.834 196.916 121.834 199.75V200.459C124.666 218.166 136 230.916 159.375 234.459V251.459C159.375 254.291 161.5 256.416 165.041 257.125H175.666C178.5 257.125 180.625 255 181.334 251.459V234.459C202.584 230.916 216.75 216.041 216.75 196.916Z"
          fill="white"
        />
        <path
          d="M133.875 271.291C78.625 251.459 50.2911 189.834 70.8339 135.291C81.4589 105.541 104.834 82.875 133.875 72.25C136.709 70.8339 138.125 68.7089 138.125 65.1661V55.25C138.125 52.4161 136.709 50.2911 133.875 49.5839C133.166 49.5839 131.75 49.5839 131.041 50.2911C63.75 71.5411 26.9161 143.084 48.1661 210.375C60.9161 250.041 91.375 280.5 131.041 293.25C133.875 294.666 136.709 293.25 137.416 290.416C138.125 289.709 138.125 289 138.125 287.584V277.666C138.125 275.541 136 272.709 133.875 271.291ZM208.959 50.2911C206.125 48.875 203.291 50.2911 202.584 53.125C201.875 53.8339 201.875 54.5411 201.875 55.9589V65.875C201.875 68.7089 204 71.5411 206.125 72.9589C261.375 92.7911 289.709 154.416 269.166 208.959C258.541 238.709 235.166 261.375 206.125 272C203.291 273.416 201.875 275.541 201.875 279.084V289C201.875 291.834 203.291 293.959 206.125 294.666C206.834 294.666 208.25 294.666 208.959 293.959C276.25 272.709 313.084 201.166 291.834 133.875C279.084 93.5 247.916 63.0411 208.959 50.2911Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_2727">
          <rect width="340" height="340" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(USDCoinLogo);
