
import { memo, SVGProps } from "react";

const LinkIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.27679 18C3.14272 18 1.21877 16.7145 0.401958 14.7429C-0.414851 12.7713 0.0363144 10.5018 1.5451 8.9926L3.78454 6.75316L5.27679 8.24542L3.03841 10.4838C2.23853 11.2837 1.92614 12.4495 2.21891 13.5422C2.51169 14.6349 3.36516 15.4883 4.45782 15.7811C5.55048 16.0739 6.71634 15.7615 7.51622 14.9616L9.7546 12.7232L11.2469 14.2166L9.00848 16.4549C8.02075 17.4475 6.67708 18.0038 5.27679 18ZM6.02291 13.4694L4.53066 11.9771L11.993 4.51478L13.4852 6.00703L6.02397 13.4683L6.02291 13.4694ZM14.2324 11.231L12.7391 9.73873L14.9775 7.50034C15.7883 6.70285 16.109 5.53178 15.8178 4.43244C15.5266 3.33311 14.6681 2.47443 13.5688 2.18294C12.4696 1.89145 11.2984 2.21193 10.5007 3.02252L8.26129 5.26091L6.76904 3.76865L9.00848 1.52921C11.0718 -0.516082 14.4002 -0.508793 16.4545 1.54552C18.5088 3.59982 18.5161 6.92826 16.4708 8.99155L14.2324 11.2299V11.231Z" fill="currentColor"/>
        </svg>
    );
};

export default memo(LinkIcon);
