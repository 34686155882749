import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ContactUs from "../Main/components/ContactUs/ContactUs";
import LayoutSection from "../../components/Layout/LayoutSection/LayoutSection";
import scss from "./styles.module.scss";

const PrivacyPolicy = () => {
  return (
    <div>
      <LayoutSection
        sectionClassName={scss.pageHeaderBlock}
        contentClassName={scss.sectionContentWrapper}
        style={{
          backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
        }}
      >
        <Breadcrumbs position="left" title="Privacy Policy" />
        <h1 className={scss.pageTitle}>PRIVACY POLICY</h1>
      </LayoutSection>
      <div className={scss.contentWrapper}>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Collection of Information</h4>
          <p className={scss.textItemDescription}>
            Act4 FX may collect personal information from you through various
            methods, including account application forms, newsletters, seminars,
            or over the telephone. We strive to collect information directly
            from you whenever reasonable and feasible. The information we may
            collect from you includes your name, address, telephone number,
            email address, financial information, investment experience, and
            other relevant details. We only collect personal information that is
            reasonably necessary to provide you with quality products or
            services, assess your applications or inquiries, and maintain your
            contact details. We generally retain personal information related to
            the products and services we provide or have provided to you, your
            financial interests, your financial position, and your contact
            details.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Use of Information</h4>
          <p className={scss.textItemDescription}>
            We use and disclose your personal information for the purpose for
            which it was provided to us or for related purposes that can be
            reasonably expected without your permission. For example, we may use
            your personal information to inform you of investment opportunities
            or provide information about products and services that may interest
            you. However, we respect your right to request that we refrain from
            such communications. We do not sell personal information to other
            organizations for these purposes. We may disclose personal
            information to service providers who assist us in delivering our
            products and services, or with your permission, to your nominated
            financial adviser.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Data Accuracy and Updates</h4>
          <p className={scss.textItemDescription}>
            We take reasonable steps to ensure the accuracy of the information
            we hold. If you believe that the information we have about you is
            inaccurate or incomplete, you can contact us to request its
            correction. The information provided is based on good faith and
            reliable sources at the indicated date. Act4 FX, its related bodies
            corporate, directors, or officers do not make any representation or
            warranty regarding the reliability, completeness, fitness for
            purpose, or accuracy of the information or any third-party websites
            linked to our website. Any use of the information is at your own
            risk, and you should consult Act4 FX for the availability of more
            recent information that may impact your investment decision.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Data Security</h4>
          <p className={scss.textItemDescription}>
            We employ security procedures and utilize the latest technology to
            protect the information we hold. Access to and use of personal
            information within Act4 FX is appropriately restricted to prevent
            misuse or unauthorized disclosure. When we engage other
            organizations to provide support services, we ensure that they also
            safeguard the privacy of the information provided to them. We
            securely delete or permanently de-identify personal information that
            is no longer required.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Access to Your Information</h4>
          <p className={scss.textItemDescription}>
            You can request access to your information by contacting us. In most
            cases, we will provide you with full access to your information.
            However, there may be legal or administrative reasons to deny
            access, in which case we will explain the reason to you.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Cookie Policy</h4>
          <p className={scss.textItemDescription}>
            While browsing our website www.act4fx.net, advertising and campaign
            tracking cookies may be placed on your computer to understand your
            interests. Our display advertising partners, including Adwords,
            Adroll, Bing-Yahoo networks, etc., allow us to show you retargeting
            advertising on other sites based on your previous interactions with
            www.act4fx.net. These techniques do not collect personal information
            such as your name, email address, postal address, or telephone
            number.
          </p>
        </div>
      </div>
      <ContactUs />
    </div>
  );
};

export default PrivacyPolicy;
