import MT5Home from "../components/Home/MT5Home";
import Offers from "../components/Offers/Offers";
import AdvantagesBenefits from "../components/AdvantagesBenefits/AdvantagesBenefits";

const MT5Android = () => {
  return (
    <main>
      <MT5Home
        breadCrumbs="MT5 Android"
        title="MT5 Android"
        subtitle="Take full control of your account on your phone"
        description="With MetaTrader 5 Android, you can access your trading account from any Android device, giving you the flexibility and peace of mind to trade on the go. No matter where you are or what you're doing, HotForex Android keeps you connected to the markets."
        links={homeLinks}
        image="/assets/mt5-android.webp"
      />
      <Offers
        title="Android MT5: Small Devices – Big Power"
        items={offersItems}
        columnsCount={3}
        links={offers}
      />
      <AdvantagesBenefits
        title="ADVANTAGES & BENEFITS"
        benefits={benefitsItems}
        instructions={instructionsItems}
      />
    </main>
  );
};

export default MT5Android;

const homeLinks = [
  {
    title: "DOWNLOAD",
    link: "https://download.mql5.com/cdn/mobile/mt5/android?utm_source=www.metaquotes.net&utm_campaign=install.metaquotes",
  },
];

const offers = [
  {
    content: "OPEN AN ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
  {
    content: "DEMO ACCOUNT",
    link: "https://my.act4fx.net/en/register",
  },
];

const offersItems = [
  {
    title: "Dynamic charting experience",
  },
  {
    title: "Wide range of timeframes",
  },
  {
    title: "Efficient one-click trading",
  },
  {
    title: "Advanced trading features",
  },
  {
    title: "Comprehensive selection of 30 technical indicators",
  },
  {
    title: "Access to 24 analytical tools",
  },
];

const benefitsItems = [
  {
    content: "Enjoy full access to your account and order history",
  },
  {
    content: "Maintain complete control over your trades at all times",
  },
  {
    content: "Utilize enhanced order management capabilities",
  },
];

const instructionsItems = [
  [
    {
      content: "Steps to uninstall an Android MT5 App",
      header: true,
    },
    {
      content:
        "Go to Menu > Settings > Applications > Manage applications (on devices running Android 4.0 Ice Cream Sandwich, go to Settings > Apps)",
      showNumber: true,
    },
    {
      content:
        "Scroll through the list of apps and locate the app you want to uninstall",
      showNumber: true,
    },
    {
      content: "Tap on the app you want to uninstall",
      showNumber: true,
    },
    {
      content: "Tap on 'Uninstall'",
      showNumber: true,
    },
  ],
];
