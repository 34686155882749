import React from "react";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Typography from "../../../../components/Typography/Typography";
import WarningIcon from "../../../../icons/WarningIcon";
import Separator from "../../../../components/shared/Separator/Separator";
import Button from "../../../../components/shared/Button/Button";
import scss from "./styles.module.scss";

const TradingHoursContentSection = () => {
  return (
    <LayoutSection
      contentClassName={scss.layoutSectionContent}
      sectionClassName={scss.layoutSection}
    >
      <div className={scss.titleWrapper}>
        <h2 className={scss.sectionTitle}>
          Prior to the market open, the Trading Desk updates rates to reflect
          current market pricing.
        </h2>
      </div>
      <div className={scss.contentWrapper}>
        <Typography className={scss.defaultText}>
          During this time, trades and orders held over the weekend are
          processed for execution. However, quotes during this period cannot be
          used for new market orders. Once the market opens, traders can enter
          new trades and make changes to existing orders.
        </Typography>
        <div className={scss.warningWrapper}>
          <div className={scss.warningIconWrapper}>
            <WarningIcon />
          </div>
          <Typography className={scss.warningText}>
            PLEASE NOTE THAT THE MARKET TENDS TO BE LESS LIQUID DURING THE FIRST
            FEW HOURS AFTER TRADING OPENS, UNTIL THE TOKYO AND LONDON MARKET
            SESSIONS BEGIN.
          </Typography>
        </div>
        <Typography className={scss.defaultText}>
          This lower liquidity can result in wider spreads and an increased
          likelihood of orders being filled at a different price than requested,
          as there are fewer buyers and sellers in the market. This is primarily
          because, during the first few hours after the open, it is still the
          weekend in most parts of the world.
        </Typography>
        <Separator
          type={"horizontal"}
          color="var(--grey-1)"
          wrapperClassName={scss.separator}
        />
        <Typography className={scss.boldText}>
          Trades and orders held over the weekend are executed at the next
          available market price based on liquidity. After the market open,
          traders can enter new trades, as well as cancel or modify existing
          orders.
        </Typography>
        <div className={scss.buttonsWrapper}>
          <Button
            buttonType={"link"}
            href="https://my.act4fx.net/en/register"
            type="default"
            horizontalPaddings="paddingL"
            size="xl"
          >
            OPEN AN ACCOUNT
          </Button>
          <Button
            buttonType={"link"}
            href="https://my.act4fx.net/en/register"
            type="outline"
            horizontalPaddings="paddingL"
            size="xl"
          >
            DEMO ACCOUNT
          </Button>
        </div>
      </div>
    </LayoutSection>
  );
};

export default TradingHoursContentSection;
