import { memo } from "react";

const FlagIcon = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.733333 12C0.328325 12 0 11.684 0 11.2941V0.705914C0 0.316067 0.328325 1.68741e-05 0.733333 1.68741e-05H5.412C5.69078 -0.00183346 5.94655 0.148619 6.072 0.388268L6.6 1.41179H10.2667C10.6717 1.41179 11 1.72783 11 2.11768V7.76472C11 8.15456 10.6717 8.4706 10.2667 8.4706H6.314C6.03786 8.46979 5.7856 8.31974 5.66133 8.08236L5.13333 7.05884H1.46667V11.2941C1.46667 11.684 1.13834 12 0.733333 12ZM1.46667 1.41179V5.64708H5.86667L6.6 7.05884H9.53333V2.82356H5.86667L5.13333 1.41179H1.46667Z"
        fill="#3A4F50"
      />
    </svg>
  );
};

export default memo(FlagIcon);
