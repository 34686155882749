import React from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  title: string;
  className?: string;
  gradientText?: boolean;
};

const TextWithShadow = ({ title, className, gradientText = true }: Props) => {
  return (
    <div
      className={cn(scss.text, { [scss.gradient]: gradientText }, className)}
    >
      {title}
    </div>
  );
};

export default TextWithShadow;
