import React from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/shared/Button/Button";
import ContactUsBox from "../../../../components/ContactUsBox/ContactUsBox";
import scss from "./styles.module.scss";

const SwapRatesHeaderSection = () => {
  return (
    <LayoutSection
      contentClassName={scss.layoutSectionContent}
      sectionClassName={scss.layoutSectionWrapper}
      style={{
        backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
      }}
    >
      <Breadcrumbs position="left" title="Swap Rates" />
      <div className={scss.sectionContentWrapper}>
        <div className={scss.contentWrapper}>
          <Typography level="h1" className={scss.sectionTitle}>
            Swap Rates
          </Typography>
          <Typography className={scss.sectionDescription}>
            At Act4 FX, we offer highly competitive swap rates that can help you
            maximize your overnight earnings or minimize your swap costs. Take
            advantage of our attractive rates across a wide range of products.
          </Typography>
          <Button
            horizontalPaddings="paddingXL"
            className={scss.button}
            buttonType="link"
            href="https://my.act4fx.net/en/register"
            type="default"
            size="xl"
          >
            START TRADING
          </Button>
        </div>
        <ContactUsBox className={scss.contactUs} />
      </div>
    </LayoutSection>
  );
};

export default SwapRatesHeaderSection;
