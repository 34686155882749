import { memo, SVGProps } from "react";

const EtherLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="340"
      height="340"
      viewBox="0 0 340 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="340" height="340" rx="170" fill="white" />
      <path
        d="M170.027 43.9194L168.336 49.6621V216.287L170.027 217.974L247.37 172.255L170.027 43.9194Z"
        fill="#343434"
      />
      <path
        d="M170.027 43.9194L92.6806 172.255L170.027 217.974V137.099V43.9194Z"
        fill="#8C8C8C"
      />
      <path
        d="M170.027 232.618L169.074 233.78V293.134L170.027 295.916L247.418 186.923L170.027 232.618Z"
        fill="#3C3C3B"
      />
      <path
        d="M170.027 295.916V232.618L92.6806 186.923L170.027 295.916Z"
        fill="#8C8C8C"
      />
      <path
        d="M170.027 217.974L247.371 172.255L170.027 137.099V217.974Z"
        fill="#141414"
      />
      <path
        d="M92.6806 172.255L170.027 217.974V137.099L92.6806 172.255Z"
        fill="#393939"
      />
    </svg>
  );
};

export default memo(EtherLogo);
