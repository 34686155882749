import { memo, SVGProps } from "react";

const LitecoinLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="340"
      height="340"
      viewBox="0 0 340 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_152_2764)">
        <path
          d="M170 321.6C253.727 321.6 321.6 253.727 321.6 170C321.6 86.2734 253.727 18.3995 170 18.3995C86.2734 18.3995 18.3995 86.2734 18.3995 170C18.3995 253.727 86.2734 321.6 170 321.6Z"
          fill="white"
        />
        <path
          d="M170 0C136.377 0 103.509 9.97033 75.5531 28.6502C47.5968 47.33 25.8075 73.8804 12.9406 104.944C0.0736578 136.007 -3.29291 170.189 3.26658 203.165C9.82607 236.142 26.017 266.433 49.7919 290.208C73.5669 313.983 103.858 330.174 136.835 336.734C169.812 343.293 203.993 339.926 235.056 327.06C266.12 314.193 292.67 292.403 311.35 264.447C330.03 236.491 340 203.623 340 170C340.065 147.74 335.745 125.685 327.286 105.095C318.828 84.5048 306.397 65.7822 290.702 49.9962C275.008 34.2103 256.358 21.6701 235.818 13.0917C215.277 4.51334 193.248 0.0647716 170.988 0H170ZM172.881 175.763L155.182 235.448H249.855C250.482 235.426 251.107 235.528 251.695 235.748C252.283 235.967 252.821 236.301 253.28 236.729C253.739 237.157 254.109 237.672 254.369 238.243C254.628 238.814 254.773 239.431 254.794 240.058V241.622L246.562 270.024C246.199 271.367 245.39 272.547 244.268 273.37C243.146 274.193 241.778 274.61 240.387 274.552H95.4965L119.782 191.816L92.6151 200.048L98.7894 181.114L125.956 172.881L160.121 56.8039C160.497 55.4683 161.309 54.2969 162.428 53.4764C163.547 52.6558 164.909 52.2333 166.295 52.276H202.93C203.557 52.254 204.182 52.3559 204.77 52.5757C205.358 52.7956 205.896 53.1291 206.355 53.5573C206.814 53.9854 207.184 54.4998 207.444 55.071C207.703 55.6422 207.848 56.259 207.869 56.8862V58.4504L179.056 156.416L206.223 148.184L200.46 167.942L172.881 175.763Z"
          fill="#345D9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_2764">
          <rect width="340" height="340" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(LitecoinLogo);
