import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import Typography from "../../../../components/Typography/Typography";
import scss from "./styles.module.scss";

const AccountComparisonsHome = () => {
  return (
    <LayoutSection
      sectionClassName={scss.section}
      contentClassName={scss.sectionContent}
      style={{
        backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
      }}
    >
      <Breadcrumbs position="left" title="Account Comparisons" />
      <Typography level="h1" className={scss.title}>
        Account Comparisons
      </Typography>
    </LayoutSection>
  );
};

export default AccountComparisonsHome;
