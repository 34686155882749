import React from "react";
import ContactUs from "../Main/components/ContactUs/ContactUs";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import LayoutSection from "../../components/Layout/LayoutSection/LayoutSection";
import scss from "./styles.module.scss";

const AmlPolicy = () => {
  return (
    <div>
      <LayoutSection
        sectionClassName={scss.pageHeaderBlock}
        contentClassName={scss.sectionContentWrapper}
        style={{
          backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
        }}
      >
        <Breadcrumbs position="left" title="AML Policy" />
        <h1 className={scss.pageTitle}>AML POLICY</h1>
      </LayoutSection>
      <div className={scss.contentWrapper}>
        <div className={scss.textItemWrapper}>
          <p className={scss.textItemDescription}>
            Act 4 Financial Ltd is dedicated to upholding the highest standards
            of compliance in relation to money laundering (AML) and
            counter-terrorism financing (CTF). The objective of the Company's
            Policy against Money Laundering and Terrorism Financing is to
            actively mitigate the risks associated with these activities. As
            mandated by law, all financial institutions must collect, verify,
            and record information that identifies individuals who open
            accounts, in order to assist the government in combating terrorism
            financing and money laundering. We are obligated to report any
            suspicious client activity that is relevant to money laundering.
            Money Laundering is the process of disguising the origins of funds
            obtained through illegal activities, such as fraud, corruption, and
            terrorism, by converting them into legitimate-looking funds or
            investments to hide their true source.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>
            The money laundering process can be divided into three sequential
            stages:
          </h4>
          <ul className={scss.textItemList}>
            <li className={scss.textItemListItem}>
              Placement: At this stage, funds obtained through illegal
              activities are converted into financial instruments like checks,
              bank accounts, and money transfers. They may also be used to
              purchase high-value goods that can be resold or physically
              deposited in banks or non-bank institutions. To avoid suspicion,
              the launderer may make multiple small deposits instead of
              depositing the entire sum at once, which is known as smurfing.
            </li>
            <li className={scss.textItemListItem}>
              Layering: The funds are transferred or moved to different accounts
              and financial instruments in order to obscure their origin and
              create confusion regarding the entity involved in multiple
              financial transactions. By moving and altering the form of funds,
              it becomes challenging to trace the laundered money.
            </li>
            <li className={scss.textItemListItem}>
              Integration: The funds are reintroduced into circulation as
              seemingly legitimate funds used to purchase goods and services.
              "Act 4 Financial Ltd" adheres to Anti-Money Laundering principles
              and actively prevents any action that facilitates or supports the
              process of legalizing illegally obtained funds. The AML policy
              aims to prevent criminals from using the company's services for
              money laundering, terrorist financing, or any other illicit
              activities.
              <br />
              To combat money laundering, "Act 4 Financial Ltd" does not accept
              or make cash transactions under any circumstances. The company
              reserves the right to suspend the operation of any client that is
              deemed illegal or associated with money laundering according to
              the staff's discretion.
            </li>
          </ul>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Company procedures</h4>
          <p className={scss.textItemDescription}>
            "Act 4 Financial Ltd" ensures that its customers are genuine
            individuals or legal entities. The company takes all necessary
            measures in accordance with the laws and regulations issued by the
            monetary authorities. The AML policy is implemented within "Act 4
            Financial Ltd" by employing the following methods:
          </p>
          <ul className={scss.textItemList}>
            <li className={scss.textItemListItem}>
              Know Your Customer (KYC) policy and due diligence.
            </li>
            <li className={scss.textItemListItem}>
              Monitoring customer activity.
            </li>
            <li className={scss.textItemListItem}>Maintaining a registry.</li>
          </ul>
          <p className={scss.textItemDescription}>
            <b>Know Your Customer (KYC)</b> - As part of the company's
            commitment to AML and KYC policies, each customer must undergo a
            verification process. Before initiating any cooperation with a
            client, "Act 4 Financial Ltd" ensures that satisfactory evidence is
            provided or alternative measures are taken to establish the client's
            identity. The company also exercises heightened scrutiny for clients
            who are residents of countries identified by credible sources as
            having inadequate AML standards or posing a high risk of crime,
            corruption, or terrorism financing. This includes beneficial owners
            residing in and sourcing funds from such countries.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Individual clients</h4>
          <p className={scss.textItemDescription}>
            During the registration process, each client must provide personal
            information, including their full name, birthdate, country of
            origin, and full residential address. The following documents are
            required to verify personal information: a valid passport
            (displaying the first page with the photo and signature), a driving
            license with a photograph, a national identity card (front and
            back), and documents proving the current permanent address (such as
            utility bills or bank statements) containing the customer's full
            name and place of residence. These documents must not be older than
            3 months from the submission date.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Corporate clients</h4>
          <p className={scss.textItemDescription}>
            If the applicant company is listed on a recognized or approved stock
            exchange or if there is independent evidence demonstrating that the
            applicant is a wholly owned subsidiary or under the control of such
            a company, no further verification steps are typically necessary.
            However, if the company is not listed and none of the main directors
            or shareholders have an existing account with "Act 4 Financial Ltd",
            the following documentation must be provided:
          </p>
          <ul className={scss.textItemList}>
            <li className={scss.textItemListItem}>
              Certificate of incorporation or its national equivalent.
            </li>
            <li className={scss.textItemListItem}>
              Memorandum and Articles of Association, statutory declaration, or
              their national equivalents.
            </li>
            <li className={scss.textItemListItem}>
              Certificate of good standing or other proof of the company's
              registered address.
            </li>
            <li className={scss.textItemListItem}>
              Resolution of the board of directors authorizing the opening of an
              account and granting authority to those who will operate it.
            </li>
            <li className={scss.textItemListItem}>
              Copies of powers of attorney or other authorities granted by the
              directors in relation to the company.
            </li>
            <li className={scss.textItemListItem}>
              Proof of identity of the directors if they are dealing with "Act 4
              Financial Ltd" on behalf of the client (following the individual
              identity verification rules described above).
            </li>
            <li className={scss.textItemListItem}>
              Proof of identity of the ultimate beneficiary/ beneficiaries or
              the person(s) under whose instructions the account signatories are
              authorized to act (following the individual identity verification
              rules described above).
            </li>
          </ul>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Monitoring customer activity</h4>
          <p className={scss.textItemDescription}>
            In addition to collecting customer information, "Act 4 Financial
            Ltd" continuously monitors the activity of each customer to detect
            and prevent any suspicious transactions. A suspicious transaction
            refers to any transaction that deviates from the customer's
            legitimate business or transaction history as monitored by "Act 4
            Financial Ltd". The company has implemented a system for monitoring
            transactions, both automated and manual, to prevent criminals from
            exploiting its services.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Record keeping</h4>
          <p className={scss.textItemDescription}>
            "Act 4 Financial Ltd" is required to maintain records of all
            transaction data and identification-related information, as well as
            any documents pertaining to money laundering issues (e.g.,
            suspicious activity reports, AML account monitoring documentation).
            These records are retained for a minimum of 7 years after the
            account is closed.
          </p>
        </div>
        <div className={scss.textItemWrapper}>
          <h4 className={scss.textItemTitle}>Measures taken</h4>
          <p className={scss.textItemDescription}>
            In cases where "Act 4 Financial Ltd" suspects an attempt to carry
            out transactions related to money laundering or other criminal
            activities, it will act in accordance with applicable laws and
            report the suspicious activity to the regulatory authority. The
            company reserves the right to suspend the operation of any client
            that is deemed illegal or potentially involved in money laundering,
            as determined by its staff. "Act 4 Financial Ltd" has full
            discretion to temporarily block suspicious customer accounts or
            terminate relationships with existing customers. For further
            information, please contact us at{" "}
            <a href="mailto:support@act4fx.net" className={scss.link}>
              support@act4fx.net.
            </a>
          </p>
        </div>
      </div>
      <ContactUs />
    </div>
  );
};

export default AmlPolicy;
