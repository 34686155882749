import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ResolutionDetector from "./components/ResolutionDetector/ResolutionDetector";
import Sidebar from "./components/Sidebar/Sidebar";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import ScrollToTop from "./routes/ScrollTop";

function App() {
  return (
    <>
      <ResolutionDetector />
      <BrowserRouter>
        <ScrollToTop />
        <Sidebar />
        <Header />
        <Router />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
