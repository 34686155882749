import { TradeCurrenciesDto, TradingConditionDto } from "../contracts";

export const tradingConditions: TradingConditionDto[] = [
  {
    title: "Min. deposit",
    value: "$ 100.00",
    alwaysShown: true,
    type: "simple",
  },
  {
    title: "Min. trade size",
    value: "0.01",
    alwaysShown: false,
    type: "simple",
  },
  {
    title: "Max. leverage",
    value: {
      numerator: "1",
      denominator: "500",
    },
    alwaysShown: true,
    type: "relatively",
  },
  { title: "Size increment", value: "0.01", alwaysShown: true, type: "simple" },
];

export const tradeCurrencies: TradeCurrenciesDto = [
  { from: "AUD", to: "USD" },
  { from: "Eur", to: "gbp" },
  { from: "Eur", to: "usd" },
  { from: "gbp", to: "USD" },
  { from: "gbp", to: "AUD" },
  { from: "usd", to: "uah" },
];
