import TextWithShadow from "../shared/TextWithShadow/TextWithShadow";
import Typography from "../Typography/Typography";
import Button from "../shared/Button/Button";
import EmailIcon from "../../icons/EmailIcon";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  className?: string;
};

const ContactUsBox = ({ className }: Props) => {
  return (
    <div className={cn(scss.root, className)}>
      <TextWithShadow title="CONTACT US" className={scss.title} />
      <Typography level="h5" className={scss.description}>
        Our support team is available 24/7, Monday to Sunday
      </Typography>
      <div className={scss.buttonWrapper}>
        <Button
          className={scss.button}
          buttonType="link"
          type="outFilledAccent"
          size="xs"
          href="mailto:support@act4fx.net"
        >
          <EmailIcon color="var(--blue-1)" />
          support@act4fx.net
        </Button>
      </div>
    </div>
  );
};

export default ContactUsBox;
