import React from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

export type CryptoConditionsTableDto = {
  table: {
    rows: {
      type: "heading" | "content";
      items: {
        key: string;
        value: string;
      }[];
    }[];
  };
};

const PaymentProcessingTable = ({ table }: CryptoConditionsTableDto) => {
  return (
    <div className={scss.tableWrapper}>
      {table.rows.map((row, index) => (
        <div
          className={cn(scss.tableRowGridWrapper, {
            [scss.heading]: row.type === "heading",
          })}
          key={index}
        >
          {row.items.map((item, i) => (
            <div
              key={`${item.key}${i}${item.value}`}
              className={cn(scss.tableItem, scss[`block${i + 1}`], {
                [scss.rowTitle]: i === 0,
              })}
            >
              <div className={scss.tableItemKey}>{item.key}</div>
              <div className={scss.tableItemValue}>{item.value}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PaymentProcessingTable;
