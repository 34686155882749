import React from "react";
import TradingHoursHeadingSection from "./components/TradingHoursHeadingSection/TradingHoursHeadingSection";
import TradingHoursContentSection from "./components/TradingHoursContentSection/TradingHoursContentSection";

const TradingHours = () => {
  return (
    <>
      <TradingHoursHeadingSection />
      <TradingHoursContentSection />
    </>
  );
};

export default TradingHours;
