import { observer } from "mobx-react-lite";
import { useEffect, useRef, MouseEvent } from "react";
import { createPortal } from "react-dom";
import scss from "./styles.module.scss";
import classNames from "classnames";
import sidebarStore from "../../store/SidebarStore";

const Sidebar = observer(() => {
  const state = sidebarStore.state;
  const root = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    root.current.id = "sidebar";
    document.body.append(root.current);
  }, []);

  useEffect(() => {
    const header = document.querySelector("header");
    if (state.opened) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "var(--scroll-width)";
      if (header) header.style.paddingRight = "var(--scroll-width)";
    }

    return () => {
      document.body.style.overflow = "hidden auto";
      document.body.style.paddingRight = "unset";
      if (header) header.style.paddingRight = "unset";
    };
  }, [state.opened]);

  const onOutsideClick = (e: MouseEvent) => {
    if (e.currentTarget === e.target) sidebarStore.closeMenu();
  };

  return createPortal(
    <div
      className={classNames(scss.overlay, { [scss.active]: state.opened })}
      onClick={onOutsideClick}
    >
      <div className={classNames(scss.panel, { [scss.active]: state.opened })}>
        {state.content}
      </div>
    </div>,
    root.current
  );
});

export default Sidebar;
