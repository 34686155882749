import React from "react";
import Button from "../../../../components/shared/Button/Button";
import { TradingConditionDto } from "../../../../api/contracts";
import TradingConditions from "./components/TradingConditions/TradingConditions";
import Text370 from "../../../../icons/Text370";
import scss from "./styles.module.scss";

interface IProps {
  currentConditions: TradingConditionDto[];
}

const HeadingSection = ({ currentConditions }: IProps) => {
  return (
    <div
      className={scss.headingSectionWrapper}
      style={{
        backgroundImage: "url(/assets/home-page-header-section-bg.webp)",
      }}
    >
      <div className={scss.sectionLayout}>
        <div className={scss.homeSectionWrapper}>
          <div className={scss.leftSideContentWrapper}>
            <p className={scss.headingSectionCaption}>
              Leading the market in trading platforms
            </p>
            {/*Choose us as your trusted brokerage for reliable investments.*/}
            <h1 className={scss.headingSectionTitle}>
              Choose us as your
              <span>trusted brokerage</span>
              for reliable investments
            </h1>
            <p className={scss.headingSectionSubtitle}>
              Utilizing advanced technology to provide optimal trading
              conditions
            </p>
            <div className={scss.mainSectionButtonsWrapper}>
              <Button
                className={scss.headingSectionButton}
                size="xl"
                type="default"
                horizontalPaddings="paddingL"
                buttonType="link"
                href="https://my.act4fx.net/en/register"
              >
                get started
              </Button>
              <Button
                className={scss.headingSectionButton}
                size="xl"
                type="outlineAccent"
                horizontalPaddings="paddingL"
                buttonType="link"
                href="https://my.act4fx.net/en/register"
              >
                demo version
              </Button>
            </div>
            <TradingConditions items={currentConditions} />
          </div>
          <div className={scss.rightSideContentWrapper}>
            <div className={scss.rightSideTextWrapper}>
              <p className={scss.rightSideCaption}>
                Trade freely without any limitations.
              </p>
              <p className={scss.rightSideTitle}>
                Access all the popular trading pairs conveniently on our
                platform.
              </p>
              <p className={scss.rightSideSubtitle}>
                Currently, we offer around 370 trading pairs, and we're
                continuously expanding our selection.
              </p>
            </div>
            <Text370 className={scss.rightSideTextSvg} />
          </div>
        </div>
        <div className={scss.cardWrapper}>
          <p className={scss.cardCaption}>
            Spreads that set the industry standard.
          </p>
          <h2 className={scss.cardTitle}>
            Enjoy competitive spreads that cater to all types of trading
            strategies.
          </h2>
          <p className={scss.cardSubtitle}>
            Experience fast execution, reliability, exceptional customer
            support, and a wide range of available trading platforms.
          </p>
          <Button
            className={scss.cardButton}
            size="xl"
            type="default"
            horizontalPaddings="paddingL"
            buttonType="link"
            href="https://my.act4fx.net/en/register"
          >
            start trading
          </Button>
        </div>
      </div>
      <div className={scss.imageWrapper}>
        <img src="/assets/hand-with-phone.webp" alt="hand-with-phone" />
      </div>
    </div>
  );
};

export default HeadingSection;
