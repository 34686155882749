import React from "react";
import PaymentProcessingHeaderSection from "./components/PaymentProcessingHeaderSection/PaymentProcessingHeaderSection";
import PaymentProcessingGuideSection from "./components/PaymentProcessingGuideSection/PaymentProcessingGuideSection";

const PaymentProcessing = () => {
  return (
    <>
      <PaymentProcessingHeaderSection />
      <PaymentProcessingGuideSection />
    </>
  );
};

export default PaymentProcessing;
