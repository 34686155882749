import { CryptoCurrencyDto } from "../contracts";
import TeetherLogo from "../../icons/crypto/TeetherLogo";
import USDCoinLogo from "../../icons/crypto/USDCoinLogo";
import BitcoinLogo from "../../icons/crypto/BitcoinLogo";
import EtherLogo from "../../icons/crypto/EtherLogo";
import LitecoinLogo from "../../icons/crypto/LitecoinLogo";
import DashLogo from "../../icons/crypto/DashLogo";
import MoneroLogo from "../../icons/crypto/MoneroLogo";
import BitcoinCashLogo from "../../icons/crypto/BitcoinCashLogo";



export const cryptoCurrencies: CryptoCurrencyDto[] = [
  {
    icon: <TeetherLogo width={24} height={24} />,
    title: "USDT",
    caption: "Tether"
  },
  {
    icon: <USDCoinLogo width={24} height={24} />,
    title: "USDC",
    caption: "USD Coin"
  },
  {
    icon: <BitcoinLogo width={24} height={24} />,
    title: "BTC",
    caption: "Bitcoin"
  },
  {
    icon: <EtherLogo width={24} height={24} />,
    title: "ETH",
    caption: "Ethereum"
  },
  {
    icon: <LitecoinLogo width={24} height={24} />,
    title: "LTC",
    caption: "Litecoin"
  },
  {
    icon: <DashLogo width={24} height={24} />,
    title: "DASH",
    caption: "Dash"
  },
  {
    icon: <BitcoinCashLogo width={24} height={24} />,
    title: "BCH",
    caption: "Bitcoin Cash"
  },
  {
    icon: <MoneroLogo width={24} height={24} />,
    title: "XMR",
    caption: "Monero"
  }
]
