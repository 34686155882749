import React, { ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";
import ChevronDownIcon from "../../../../icons/ChevronDownIcon";

type Props = {
  children: ReactNode;
  title: string;
};

const setMaxHeight = (element: HTMLDivElement) =>
  (element.style.maxHeight = `${element.scrollHeight}px`);

const BurgerNavRow = (props: Props) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (opened && ref.current) setMaxHeight(ref.current);
  }, [opened]);

  useEffect(() => {
    const element = refContent.current;
    const resizeObserver = new ResizeObserver(() => {
      ref.current && setMaxHeight(ref.current);
    });
    element && resizeObserver.observe(element);
    return () => {
      element && resizeObserver.unobserve(element);
    };
  }, []);

  return (
    <div className={cn(scss.row, { [scss.opened]: opened })}>
      <div className={scss.rowInner} onClick={() => setOpened((p) => !p)}>
        <div className={scss.rowTitle}>{props.title}</div>
        <ChevronDownIcon
          className={cn(scss.rowIcon, { [scss.opened]: opened })}
          width={24}
          height={24}
        />
      </div>
      <div ref={ref} className={cn(scss.rowContent, { [scss.opened]: opened })}>
        <div ref={refContent} className={scss.rowContentInner}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default BurgerNavRow;
