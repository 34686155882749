import { memo, SVGProps } from "react";

const PlusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.30022 12.528V8.208H0.956219V4.896H5.30022V0.576H8.70822V4.896H13.0522V8.208H8.70822V12.528H5.30022Z"
        fill="#3A4F50"
      />
    </svg>
  );
};

export default memo(PlusIcon);
