import React from "react";
import SwapRatesHeaderSection from "./components/SwapRatesHeaderSection/SwapRatesHeaderSection";
import SwapRatesAboutSection from "./components/SwapRatesAboutSection/SwapRatesAboutSection";
import SwapRatesBottomSection from "./components/SwapRatesBottomSection/SwapRatesBottomSection";

const SwapRates = () => {
  return (
    <>
      <SwapRatesHeaderSection />
      <SwapRatesAboutSection />
      <SwapRatesBottomSection />
    </>
  );
};

export default SwapRates;
