import React from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import Button from "../../../../components/shared/Button/Button";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import TextWithShadow from "../../../../components/shared/TextWithShadow/TextWithShadow";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../store/ResolutionStore";
import Typography from "../../../../components/Typography/Typography";
import scss from "./styles.module.scss";

const TradingHoursHeadingSection = () => {
  const { phone, phoneLg, tablet, tabletMd } = resolutionStore.resolution;
  const isMobile = phone || phoneLg || tablet || tabletMd;
  return (
    <LayoutSection
      contentClassName={scss.layoutSectionContent}
      sectionClassName={scss.layoutSectionWrapper}
      style={{
        backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
      }}
    >
      <Breadcrumbs position="left" title="Trading Hours" />
      <div className={scss.contentWrapper}>
        <div className={scss.contentTextWrapper}>
          <Typography level="h1" className={scss.sectionTitle}>
            Trading Hours
          </Typography>
          {isMobile && (
            <div className={scss.contentDaysWrapper}>
              <div className={scss.dayItemWrapper}>
                <p className={scss.day}>monday</p>
                <TextWithShadow className={scss.time} title="00:00:51" />
              </div>
              <div className={scss.dayItemWrapper}>
                <p className={scss.day}>friday</p>
                <TextWithShadow className={scss.time} title="23:59:59" />
              </div>
            </div>
          )}
          <Typography className={scss.description}>
            The open trading hours of Act4 FX Metatrader Platform for Foreign
            Exchange instruments
          </Typography>
          <Button
            horizontalPaddings="paddingXL"
            className={scss.button}
            buttonType="link"
            href="https://my.act4fx.net/en/register"
            type="default"
            size="xl"
          >
            START TRADING
          </Button>
          <Typography className={scss.text}>
            Please note that the Server Time is adjusted for Daylight Saving
            Time (DST), which begins on the last Sunday of March and ends on the
            last Sunday of October. During the winter, the Server Time is GMT+2,
            and during the summer (DST), it is GMT+3.
          </Typography>
        </div>
        {!isMobile && (
          <div className={scss.contentDaysWrapper}>
            <div className={scss.dayItemWrapper}>
              <p className={scss.day}>monday</p>
              <TextWithShadow className={scss.time} title="00:00:51" />
            </div>
            <div className={scss.dayItemWrapper}>
              <p className={scss.day}>friday</p>
              <TextWithShadow className={scss.time} title="23:59:59" />
            </div>
          </div>
        )}
      </div>
    </LayoutSection>
  );
};

export default observer(TradingHoursHeadingSection);
