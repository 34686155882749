import HeaderSection from "./components/HeaderSection/HeaderSection";

const PortfolioDiversification = () => {
  return (
    <main>
      <HeaderSection />
    </main>
  );
};

export default PortfolioDiversification;
