import React from "react";
import { TradingConditionDto } from "../../../../../../api/contracts";
import TradingCondition from "./TradingCondition";
import scss from "./styles.module.scss";

interface IProps {
  items: TradingConditionDto[];
}

const TradingConditions = ({ items }: IProps) => {
  return (
    <div className={scss.tradingConditionsWrapper}>
      {items.map((item, i) => (
        <TradingCondition item={item} key={i} />
      ))}
    </div>
  );
};

export default TradingConditions;
