import { memo, SVGProps } from "react";

const BitcoinLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="340"
      height="340"
      viewBox="0 0 340 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_152_2736)">
        <path
          d="M334.913 211.124C312.206 302.196 219.954 357.621 128.861 334.91C37.8056 312.206 -17.6255 219.958 5.09177 128.894C27.7891 37.8113 120.041 -17.6177 211.106 5.08704C302.193 27.7918 357.621 120.049 334.911 211.126L334.912 211.124H334.913Z"
          fill="#F7931A"
        />
        <path
          d="M244.971 145.779C248.355 123.156 231.129 110.995 207.574 102.883L215.216 72.2373L196.559 67.589L189.12 97.4274C184.215 96.2043 179.178 95.0518 174.172 93.9092L181.665 63.8739L163.02 59.2256L155.374 89.8608C151.315 88.9368 147.329 88.0236 143.461 87.0614L143.483 86.965L117.755 80.541L112.792 100.465C112.792 100.465 126.634 103.638 126.342 103.833C133.897 105.719 135.263 110.719 135.036 114.683L126.332 149.595C126.852 149.727 127.527 149.918 128.272 150.217C127.649 150.063 126.987 149.894 126.299 149.73L114.099 198.636C113.176 200.932 110.832 204.376 105.55 203.068C105.737 203.339 91.9901 199.684 91.9901 199.684L82.7273 221.038L107.006 227.09C111.523 228.222 115.949 229.407 120.308 230.521L112.587 261.518L131.223 266.166L138.868 235.499C143.959 236.881 148.9 238.155 153.736 239.357L146.116 269.88L164.774 274.528L172.494 243.59C204.307 249.61 228.229 247.183 238.298 218.41C246.411 195.245 237.894 181.884 221.157 173.17C233.347 170.359 242.529 162.342 244.977 145.782L244.972 145.778L244.971 145.779ZM202.344 205.546C196.579 228.711 157.571 216.188 144.925 213.048L155.17 171.983C167.816 175.14 208.37 181.387 202.345 205.546H202.344ZM208.114 145.444C202.855 166.515 170.389 155.81 159.857 153.185L169.145 115.941C179.677 118.566 213.593 123.465 208.116 145.444H208.114Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_2736">
          <rect width="340" height="340" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(BitcoinLogo);
