import { memo, SVGProps } from "react";

const BitcoinCashLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="340"
      height="340"
      viewBox="0 0 340 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_152_2782)">
        <path
          d="M170 340C263.888 340 340 263.888 340 170C340 76.1116 263.888 0 170 0C76.1116 0 0 76.1116 0 170C0 263.888 76.1116 340 170 340Z"
          fill="#0AC18E"
        />
        <path
          d="M223.028 112.916C214.485 93.5431 194.853 89.401 170.82 93.4137L163.096 63.4695L144.888 68.1726L152.482 98.0305C147.693 99.2386 142.774 100.274 137.898 101.655L130.305 71.9695L112.096 76.6726L119.82 106.617C115.893 107.739 83.0583 116.152 83.0583 116.152L88.0634 135.655C88.0634 135.655 101.439 131.901 101.31 132.203C108.731 130.261 112.226 133.972 113.865 137.467L135.094 219.533C135.353 221.906 134.921 225.962 129.83 227.343C130.132 227.515 116.584 230.751 116.584 230.751L118.568 253.49C118.568 253.49 151.102 245.162 155.373 244.084L163.183 274.373L181.391 269.67L173.581 239.165C178.586 238 183.462 236.792 188.208 235.541L195.975 265.873L214.183 261.17L206.373 230.924C234.419 224.107 254.223 206.416 250.167 179.363C247.579 163.053 229.759 149.678 214.959 148.168C224.063 140.099 228.68 128.32 223.028 112.916ZM214.269 184.325C217.893 211.119 180.657 214.398 168.36 217.635L157.66 177.551C170 174.315 208.142 160.723 214.269 184.325ZM191.832 129.744C195.673 153.561 163.83 156.322 153.561 158.954L143.81 122.581C154.122 120.036 184.023 107.695 191.832 129.744Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_2782">
          <rect width="340" height="340" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(BitcoinCashLogo);
