import cn from "classnames";
import { CSSProperties, ReactNode } from "react";
import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Typography from "../../../../components/Typography/Typography";
import Separator from "../../../../components/shared/Separator/Separator";
import InstructionsGrid from "./InstructionsGrid";
import scss from "./styles.module.scss";

type Props = {
  title: string;
  benefits: Array<{ content: ReactNode }>;
  instructions?: Array<
    Array<{
      content: ReactNode;
      header?: boolean;
      unite?: boolean;
      showNumber?: boolean;
    }>
  >;
};

const AdvantagesBenefits = (props: Props) => {
  const style = {
    "--grid-columns": props.benefits.length,
  } as CSSProperties;
  return (
    <LayoutSection
      sectionClassName={scss.section}
      contentClassName={scss.sectionContent}
    >
      <Typography level="h3">{props.title}</Typography>
      <div
        className={cn(scss.benefits, {
          [scss.even]: props.benefits.length % 2 === 0,
        })}
        style={style}
      >
        {props.benefits?.map((item, i) => (
          <div className={scss.benefitsItem} key={i}>
            {item.content}
          </div>
        ))}
      </div>

      {!!props.instructions ? (
        <>
          <Separator type="horizontal" color="var(--grey-1)" />
          <InstructionsGrid instructions={props.instructions} />
        </>
      ) : null}
    </LayoutSection>
  );
};

export default AdvantagesBenefits;
