import { memo, SVGProps } from "react";

const Metatrader5Icon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.2106 33.845C26.7365 33.845 31.2162 29.3621 31.2162 23.8321C31.2162 18.302 26.7365 13.8191 21.2106 13.8191C15.6847 13.8191 11.2051 18.302 11.2051 23.8321C11.2051 29.3621 15.6847 33.845 21.2106 33.845Z"
        fill="url(#paint0_linear_112_249)"
      />
      <path
        d="M21.2108 31.8094C25.6133 31.8094 29.1823 28.2378 29.1823 23.832C29.1823 19.4262 25.6133 15.8546 21.2108 15.8546C16.8082 15.8546 13.2393 19.4262 13.2393 23.832C13.2393 28.2378 16.8082 31.8094 21.2108 31.8094Z"
        fill="#262B36"
      />
      <path
        d="M21.2427 10.6132C23.4668 10.6132 25.2697 8.37729 25.2697 5.61912C25.2697 2.86094 23.4668 0.625 21.2427 0.625C19.0187 0.625 17.2158 2.86094 17.2158 5.61912C17.2158 8.37729 19.0187 10.6132 21.2427 10.6132Z"
        fill="#A5D647"
      />
      <path
        d="M27.6511 14.7091C27.9592 13.8833 28.1907 13.0309 28.3428 12.1626C28.9769 12.4758 29.4545 14.9399 29.3886 16.2255C31.2206 18.1683 32.2983 20.7024 32.4274 23.3705C33.745 21.3515 37.6072 7.38278 25.9546 3.92151C26.5805 10.7781 21.9112 11.5528 21.3677 11.5693C20.8242 11.5857 15.7597 10.9347 16.4267 4.04513C8.76815 5.71807 8.12582 12.7725 8.34816 15.3602C10.0096 14.4147 11.8607 13.8517 13.7668 13.712C13.8929 13.1411 14.1558 12.6094 14.5327 12.1626C14.5327 12.7725 14.5738 13.1186 14.5821 13.6213C14.9526 13.5719 15.9409 13.473 17.2255 13.4235C18.9373 12.7715 20.7827 12.5493 22.6002 12.7763C24.4177 13.0033 26.1519 13.6726 27.6511 14.7256V14.7091Z"
        fill="#A5D647"
      />
      <path
        d="M41.361 35.2696C42.4733 33.3424 41.4403 30.6617 39.0536 29.2822C36.667 27.9027 33.8305 28.3467 32.7182 30.274C31.6058 32.2013 32.6389 34.882 35.0255 36.2615C37.4121 37.641 40.2486 37.1969 41.361 35.2696Z"
        fill="#03AAFF"
      />
      <path
        d="M31.8007 29.8811C32.0807 29.3866 35.1524 25.3402 40.7687 29.3866C43.1898 21.9037 37.4088 17.8491 35.0536 16.72C35.0596 18.637 34.6316 20.5303 33.8018 22.2581C34.2272 22.657 34.5517 23.1513 34.7489 23.7003L33.456 23.0162C33.316 23.3541 32.9289 24.2194 32.3525 25.3238C32.1175 27.1688 31.4288 28.9266 30.348 30.4397C29.2672 31.9528 27.8281 33.1738 26.1597 33.9934C26.6773 34.5873 27.2419 35.1385 27.8479 35.6416C27.3209 35.9878 25.3774 35.3614 24.1669 34.7269C21.5712 35.4252 18.8118 35.1739 16.3848 34.0181C18.3447 36.886 27.8067 45.5804 36.1241 37.7102C29.8737 34.801 31.5372 30.3591 31.8007 29.8811Z"
        fill="#03AAFF"
      />
      <path
        d="M9.68952 30.2435C8.56956 28.3151 5.73671 27.8783 3.34856 29.2546C0.960402 30.6308 -0.0360355 33.3174 1.08393 35.2376C2.20389 37.1578 5.03674 37.6111 7.41666 36.2266C9.79658 34.8421 10.826 32.1719 9.68952 30.2435Z"
        fill="#EDCF52"
      />
      <path
        d="M9.99399 23.9062C9.99399 23.4776 9.99399 23.0821 10.0681 22.637C9.18606 22.7846 8.32017 23.0164 7.48231 23.3293C7.4329 22.5958 9.45048 20.857 10.5787 20.3295C11.1924 18.5019 12.2675 16.8639 13.6997 15.5744C14.1935 15.0532 14.7462 14.5913 15.3468 14.1981C13.8068 13.8108 -1.37857 17.2638 1.6025 29.4689C7.21055 25.4885 10.2246 29.1393 10.5046 29.609C10.7846 30.0788 12.7774 34.8092 6.47764 37.71C11.7398 43.4788 18.1631 40.5203 20.296 39.0286C18.6316 38.0779 17.2041 36.7621 16.1208 35.18C15.5652 35.3444 14.9797 35.381 14.408 35.2872L15.6432 34.463C15.462 34.2241 15.0503 33.6389 14.548 32.889C13.1411 31.8511 11.9966 30.4982 11.2058 28.9384C10.415 27.3786 10 25.6552 9.99399 23.9062Z"
        fill="#EDCF52"
      />
      <path
        d="M21.1906 28.3563C22.9058 28.3563 24.0244 27.1681 24.0244 25.2973C24.0244 23.2748 22.8632 22.4405 21.4143 22.4405C20.9988 22.4405 20.7325 22.5037 20.4342 22.5922L20.5194 21.0627H23.6942V19.3563H19.1132L18.9001 23.7172L19.8483 24.463C20.1998 24.286 20.626 24.1344 21.0947 24.1344C21.8724 24.1344 22.4157 24.5768 22.4157 25.3352C22.4157 26.1442 21.9363 26.5866 21.1693 26.5866C20.5088 26.5866 19.9548 26.2327 19.3902 25.6259L18.3994 27.029C19.1238 27.8506 19.9974 28.3563 21.1906 28.3563Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_112_249"
          x1="11.1127"
          y1="35.6729"
          x2="30.5537"
          y2="18.6603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04C9FF" />
          <stop offset="0.7072" stopColor="#007EFF" />
          <stop offset="1" stopColor="#0069D4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default memo(Metatrader5Icon);
