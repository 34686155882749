import React from "react";
import scss from "./styles.module.scss";

interface IProps {
  title: string;
}

const SectionCaption = ({ title }: IProps) => {
  return <h5 className={scss.caption}>{title}</h5>;
};

export default SectionCaption;
