import {makeAutoObservable} from "mobx";

export type Resolution = { phone: boolean; phoneLg: boolean; tablet: boolean; tabletMd: boolean; tabletLg: boolean; desktop: boolean; desktopLg: boolean, desktopXlg: boolean, laptop: boolean, laptopLg: boolean, laptopHd: boolean };

class ResolutionStore {
    _resolution: Resolution = {
        phone: false,       // 360px
        phoneLg: false,     // 568px
        tablet: false,      // 768px
        tabletMd: false,    // 960px
        tabletLg: false,    // 992px
        desktop: false,     // 1024px
        desktopLg: false,   // 1200px
        desktopXlg: false,  // 1440px
        laptop: false,      // 1536px
        laptopLg: false,    // 1600px
        laptopHd: false     // 1920px
    };

    constructor() {
        makeAutoObservable(this);
    }

    setResolution = (res: Partial<Resolution>) => {
        this._resolution.phone = !!res.phone;
        this._resolution.phoneLg = !!res.phoneLg;
        this._resolution.tablet = !!res.tablet;
        this._resolution.tabletMd = !!res.tabletMd;
        this._resolution.tabletLg = !!res.tabletLg;
        this._resolution.desktop = !!res.desktop;
        this._resolution.desktopLg = !!res.desktopLg;
        this._resolution.desktopXlg = !!res.desktopXlg;
        this._resolution.laptop = !!res.laptop;
        this._resolution.laptopLg = !!res.laptopLg;
        this._resolution.laptopHd = !!res.laptopHd;
    };

    get resolution() {
        return this._resolution;
    }

    get isMobile() {
        return this._resolution.phone || this._resolution.phoneLg;
    }

    get isTablet() {
        return this._resolution.tablet || this._resolution.tabletMd || this._resolution.tabletLg;
    }

    get isMobileOrTablet() {
        return this.isMobile || this.isTablet
    }

    get isDesktop() {
        return this._resolution.desktop || this._resolution.desktopLg || this._resolution.desktopXlg
    }

    get isLaptop() {
        return this._resolution.laptop || this._resolution.laptopLg || this._resolution.laptopHd
    }

    get resolutionState() {
        return {
            isMobile: this.isMobile,
            isTablet: this.isTablet,
            isDesktop: this.isDesktop,
            isLaptop: this.isLaptop
        };
    }
}

const resolutionStore = new ResolutionStore()
export default resolutionStore;
