import LayoutSection from "../../../../components/Layout/LayoutSection/LayoutSection";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import Typography from "../../../../components/Typography/Typography";
import ContactUsBox from "../../../../components/ContactUsBox/ContactUsBox";
import Button from "../../../../components/shared/Button/Button";
import scss from "./styles.module.scss";

const HomeSection = () => {
  return (
    <LayoutSection
      sectionClassName={scss.section}
      contentClassName={scss.content}
      style={{
        backgroundImage: "url(/assets/payment-processing-heading-bg.webp)",
      }}
    >
      <Breadcrumbs position="left" title="Trading Conditions" />
      <div className={scss.container}>
        <Typography level="h1" className={scss.title}>
          Trading Conditions
        </Typography>
        <div className={scss.contentSection}>
          <Typography level="h4" className={scss.description}>
            The Act4 FX trading environment provides highly competitive pricing
            and flexible trading conditions on a global scale. It is designed to
            meet the needs of traders who seek exceptional execution quality and
            the tightest spreads in the market.
          </Typography>
          <Button
            buttonType="link"
            href="https://my.act4-fx.com/en/register"
            type="default"
            size="xl"
            horizontalPaddings="paddingL"
          >
            START TRADING
          </Button>
        </div>
        <div className={scss.contactSection}>
          <ContactUsBox />
        </div>
      </div>
    </LayoutSection>
  );
};

export default HomeSection;
