import React, { ReactNode, useEffect, useRef } from "react";
import scss from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import ChevronDownIcon from "../../../icons/ChevronDownIcon";

type Props = {
  title: string;
  subMenu: ReactNode;
};

const MenuItem = ({ subMenu, title }: Props) => {
  const subMenuWrapper = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (subMenuWrapper.current) {
      subMenuWrapper.current.style.pointerEvents = "none";
      requestAnimationFrame(() => {
        if (subMenuWrapper.current)
          subMenuWrapper.current.style.pointerEvents = "unset";
      });
    }
  }, [pathname]);

  return (
    <div className={scss.menuItemContainer}>
      <div className={scss.menuItemWrapper}>
        <p className={scss.menuItemTitle}>{title}</p>
        <ChevronDownIcon className={scss.icon} />
      </div>
      <div ref={subMenuWrapper} className={scss.subMenuWrapper}>
        {subMenu}
      </div>
    </div>
  );
};

export default MenuItem;
